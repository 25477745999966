import React from "react";
import SignupForm from "../components/Auth/SignupForm";
import GoogleButton from "../components/Auth/GoogleButton";

const SignUp = () => {
  return (
    <div className="bg-white flex mt-8 justify-center">
      <div className="mx-auto bg-[#f0f0f0] p-4 sm:p-5 w-full max-w-[90%] sm:max-w-[45%] rounded-lg mt-8 mb-8">
        <h1 className="text-center text-3xl sm:text-4xl font-semibold">
          Sign Up
        </h1>
        <p className="text-center text-richblack-500 mt-2 sm:mt-3 text-sm sm:text-base">
          We&apos;d love to hear from you, Please fill out this form.
        </p>
        <div className="flex flex-col justify-center mt-4 sm:mt-6 w-full sm:w-2/3 mx-auto">
          <GoogleButton />
          <p className="text-center text-richblack-500 mt-4 sm:mt-6">OR</p>

          <div className="mt-6 sm:mt-10 mx-auto w-full">
            <SignupForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
