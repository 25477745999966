import React from "react";
import { HiChevronRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const OrderCard = ({ order }) => {
  const { orderItems, orderStatus, color: orderColor } = order;
  const navigate = useNavigate();
  const product = orderItems[0]?.product;

  const statusClasses = {
    Processing: "text-yellow-600",
    Shipped: "text-orange-600",
    Delivered: "text-green-600",
    Cancelled: "text-red-600",
  };

  const getProductImage = () => {
    if (product?.images) {
      const matchingImage = product.images.find(
        (image) => image.color === orderColor
      );
      return matchingImage
        ? matchingImage.images_url[0]
        : product.images[0].images_url[0];
    }
    return null;
  };

  const imageUrl = getProductImage();

  const handleCardClick = () => {
    navigate(`/profile/orders/${order._id}`);
  };

  return (
    <div
      className="flex flex-col sm:flex-row p-4 border border-gray-300 rounded-lg bg-gray-50 shadow-md hover:shadow-lg transition-shadow duration-200 cursor-pointer w-full sm:w-11/12 lg:w-3/4 mb-4 mx-auto"
      onClick={handleCardClick}
    >
      {/* Product Image */}
      <div className="w-full sm:w-1/4 mb-3 sm:mb-0 sm:mr-4 flex justify-center">
        {imageUrl && (
          <img
            className="w-24 h-24 rounded-md object-cover"
            src={imageUrl}
            alt={product?.name}
          />
        )}
      </div>

      {/* Product Details */}
      <div className="flex flex-col justify-between w-full sm:w-3/4">
        <h3 className="text-lg sm:text-xl font-semibold mb-1 sm:mb-2">
          {product?.name}
        </h3>
        <p className="text-sm sm:text-base text-gray-700 mb-2">
          {product?.description?.slice(0, 40) +
            (product?.description?.length > 40 ? "..." : "")}
        </p>
        <p className={`font-medium ${statusClasses[orderStatus]}`}>
          Status: {orderStatus}
        </p>
      </div>

      {/* Right Arrow */}
      <div className="flex justify-center items-center mt-2 sm:mt-0 sm:ml-auto hover:text-blue-500">
        <HiChevronRight size={24} />
      </div>
    </div>
  );
};

export default OrderCard;
