import React from "react";
import {
  Dialog,
  Button,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { PowerIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../services/operations/auth";

export function DialogDefault() {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpen = () => setOpen(!open);

  const handleConfirm = () => {
    dispatch(logout(navigate));
    setOpen(false); // Close the dialog after logging out
  };

  return (
    <>
      <div
        onClick={handleOpen}
        className="flex items-center gap-3 p-4 cursor-pointer transition duration-300 ease-in-out"
      >
        <PowerIcon className="h-5 w-5 md:hidden" />{" "}
        {/* Icon for small screens only */}
        <span className="hidden md:inline">Log Out</span>{" "}
        {/* Text only visible on larger screens */}
      </div>

      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>Are you sure you want to log out?</DialogHeader>
        <DialogBody>
          If you log out, you'll be logged out of your session and will need to
          log back in to access your account.
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={handleConfirm} // Use the function to handle the click
            className="bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045]"
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
