import React from "react";
import ContactUsForm from "../components/ContactUs/ContactUsForm";
import Address from "../components/ContactUs/Address";

const ContactUs = () => {
  return (
    <div>
      <div className="mx-auto w-9/12 max-w-maxContent flex flex-col lg:flex-row justify-between gap-10 text-richblack-500 mt-28">
        <div className="w-full lg:w-1/2">
          <Address />
        </div>

        <div className="w-full lg:w-1/2">
          <ContactUsForm />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
