import React, { useState, useEffect } from "react";
import {
  Drawer,
  Button,
  Typography,
  IconButton,
  Input,
} from "@material-tailwind/react";
import {
  addAddressDetails,
  updateAddress,
} from "../../services/operations/profileAPI";
import { useDispatch, useSelector } from "react-redux";

export function DrawerPlacement({
  setUser,
  isEdit = false,
  setIsEdit,
  addressData = {},
}) {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openRight, setOpenRight] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    addressType: "",
    city: "",
    contactNumber: "",
    country: "",
    zipCode: "",
  });

  useEffect(() => {
    if (isEdit && addressData) {
      setFormData(addressData);
      setOpenRight(true);
    }
  }, [isEdit, addressData]);

  const openDrawerRight = () => setOpenRight(true);
  const closeDrawerRight = () => {
    setOpenRight(false), setIsEdit(false);
  };

  const handleCancel = () => {
    closeDrawerRight();
    setFormData({
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      addressType: "",
      city: "",
      contactNumber: "",
      country: "",
      zipCode: "",
    });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (isEdit) {
        const result = await updateAddress(token, addressData._id, formData);
        setUser(result.userDetails);
        // setUser(result?.addresses);
      } else {
        const result = await addAddressDetails(formData, token);
        setUser(result?.addresses);
      }
    } catch (error) {
      console.error(`Failed to ${isEdit ? "update" : "create"} address`, error);
      alert(
        `Failed to ${isEdit ? "update" : "create"} address. Please try again.`
      );
    } finally {
      setLoading(false);
      closeDrawerRight();
      setFormData({
        firstName: "",
        lastName: "",
        address1: "",
        address2: "",
        addressType: "",
        city: "",
        contactNumber: "",
        country: "",
        zipCode: "",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <React.Fragment>
      <div className="flex flex-wrap gap-4 ">
        <Button onClick={openDrawerRight} className="bg-gradient">
          Add Address
        </Button>
      </div>
      <Drawer
        placement="right"
        open={openRight}
        onClose={closeDrawerRight}
        className="p-4 w-[768px]"
      >
        <div className="mb-6 flex items-center justify-between">
          <Typography variant="h5" color="blue-gray">
            {isEdit ? "Edit Address" : "Add Address"}
          </Typography>
          <IconButton
            variant="text"
            color="blue-gray"
            onClick={closeDrawerRight}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}
        >
          <div className="overflow-y-auto max-h-[60vh] mb-6">
            <div className="flex flex-col gap-4 mb-6">
              {[
                {
                  id: "firstName",
                  label: "First Name",
                  value: formData.firstName,
                },
                {
                  id: "lastName",
                  label: "Last Name",
                  value: formData.lastName,
                },
                {
                  id: "address1",
                  label: "Address 1",
                  value: formData.address1,
                },
                {
                  id: "address2",
                  label: "Address 2",
                  value: formData.address2,
                },
                {
                  id: "addressType",
                  label: "Address Type",
                  value: formData.addressType,
                },
                { id: "city", label: "City", value: formData.city },
                {
                  id: "contactNumber",
                  label: "Contact Number",
                  value: formData.contactNumber,
                },
                { id: "country", label: "Country", value: formData.country },
                { id: "zipCode", label: "Zip Code", value: formData.zipCode },
              ].map(({ id, label, value }) => (
                <div key={id}>
                  <label
                    htmlFor={id}
                    className="block text-sm font-medium text-gray-700"
                  >
                    {label}
                  </label>
                  <Input
                    id={id}
                    name={id}
                    value={value}
                    onChange={handleChange}
                    placeholder={label}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-between">
            <Button
              // variant="outlined"
              type="button"
              onClick={handleCancel}
              className="text-gradient"
            >
              Cancel
            </Button>
            <Button type="submit" disabled={loading} className="bg-gradient">
              {loading
                ? isEdit
                  ? "Updating..."
                  : "Creating..."
                : isEdit
                ? "Save"
                : "Create"}
            </Button>
          </div>
        </form>
      </Drawer>
    </React.Fragment>
  );
}
