import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAdminData } from "../../services/operations/profileAPI";
import AdminChart from "./AdminChart";

const Dashboard = () => {
  const { user } = useSelector((state) => state.profile);
  const { token } = useSelector((state) => state.auth);
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [adminData, setAdminData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!token) {
      setError("Token is missing or invalid.");
      return;
    }

    const fetchAllProducts = async () => {
      setLoading(true);
      try {
        const response = await getAdminData(token);
        setAdminData(response);
        setAllProducts(response);
      } catch (err) {
        setError("Failed to fetch admin data. Unauthorized access.");
        console.error("GET_ADMIN_API ERROR: ", err);
      } finally {
        setLoading(false);
      }
    };

    fetchAllProducts();
  }, [token]);

  const totalAmount = Array.isArray(adminData)
    ? adminData.reduce(
        (acc, curr) => acc + (curr?.totalAmountGenerated || 0),
        0
      )
    : 0;

  const totalBuyers = Array.isArray(adminData)
    ? adminData.reduce((acc, curr) => acc + (curr?.totalBuyers || 0), 0)
    : 0;

  return (
    <div className="px-4 sm:px-6">
      <div className="space-y-2">
        <h1 className="text-xl sm:text-2xl font-bold text-richblack-900">
          Hi {user?.firstName} 👋
        </h1>
        <p className="font-medium text-richblack-200">
          Let's start something new
        </p>
      </div>

      {loading ? (
        <div className="spinner"></div>
      ) : error ? (
        <div className="text-red-500">
          <p>{error}</p>
        </div>
      ) : allProducts?.length > 0 ? (
        <div>
          <div className="my-4 flex flex-col md:flex-row h-auto md:h-[500px] space-y-4 md:space-y-0 md:space-x-4">
            {totalAmount > 0 || totalBuyers > 0 ? (
              <AdminChart allProducts={adminData} />
            ) : (
              <div className="flex-1 rounded-md bg-richblack-800 p-4 md:p-6">
                <p className="text-lg font-bold text-richblack-5">Visualize</p>
                <p className="mt-4 text-base md:text-xl font-medium text-richblack-50">
                  Not Enough Data To Visualize
                </p>
              </div>
            )}
            <div className="flex min-w-[250px] flex-col rounded-md bg-richblack-800 p-4 md:p-6">
              <p className="text-lg font-bold text-richblack-5">Statistics</p>
              <div className="mt-4 space-y-4">
                <div>
                  <p className="text-base md:text-lg text-richblack-200">
                    Total Products
                  </p>
                  <p className="text-2xl md:text-3xl font-semibold text-richblack-50">
                    {allProducts?.length}
                  </p>
                </div>
                <div>
                  <p className="text-base md:text-lg text-richblack-200">
                    Total Buyers
                  </p>
                  <p className="text-2xl md:text-3xl font-semibold text-richblack-50">
                    {totalBuyers}
                  </p>
                </div>
                <div>
                  <p className="text-base md:text-lg text-richblack-200">
                    Total Income
                  </p>
                  <p className="text-2xl md:text-3xl font-semibold text-richblack-50">
                    Rs. {totalAmount}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-md bg-richblack-800 p-4 md:p-6">
            <div className="flex items-center justify-between">
              <p className="text-lg font-bold text-richblack-5">
                Your Products
              </p>
              <Link to="/profile/allproducts">
                <p className="text-xs font-semibold text-yellow-50">View All</p>
              </Link>
            </div>
            <div className="my-4 flex flex-wrap gap-4">
              {allProducts.slice(0, 3).map((product) => (
                <div key={product._id} className="w-full sm:w-1/3">
                  <img
                    src={product?.image}
                    alt={product?.name}
                    className="h-[180px] w-full rounded-md object-cover"
                  />
                  <div className="mt-3">
                    <p className="text-sm font-medium text-richblack-50">
                      {product?.name}
                    </p>
                    <div className="mt-1 flex items-center space-x-2 text-xs font-medium text-richblack-300">
                      <p>{product?.totalBuyers} Buyers</p>
                      <p>|</p>
                      <p>Rs. {product?.discountedPrice}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-20 rounded-md bg-richblack-800 p-6 py-20">
          <p className="text-center text-2xl font-bold text-richblack-5">
            You have not created any products yet
          </p>
          <Link to="/dashboard/add-product">
            <p className="mt-1 text-center text-lg font-semibold text-yellow-50">
              Create a product
            </p>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
