export const returnPolicyContent = {
  title: "Return & Cancellation Policy",
  introduction:
    "We strive to provide a seamless shopping experience and hope you enjoy our products. However, if there are any issues, please review our policies below to understand your options for cancellations, returns, and refunds.",
  cancellation: {
    title: "Cancellation Policy",
    description:
      "Orders may be canceled prior to shipment by contacting us directly. Once an order has shipped, it cannot be canceled. To cancel your order, you can choose to decline the package upon delivery, or contact our support team as soon as possible. Orders that are refused or canceled post-shipping will be subject to return shipping fees.",
  },
  returnConditions: {
    title: "Conditions for Returns",
    description:
      "Returns are accepted only in the case of defective or incorrect items. Items must be unused, in their original packaging, and returned within 14 days of receipt. To initiate a return, please contact us with your order details and reason for return. Note that items such as personal care products, discounted items, and limited-edition products are final sale and not eligible for return.",
  },
  refund: {
    title: "Refund Policy",
    description:
      "All sales are final and refunds are generally not issued. However, in special cases, we may consider a refund after carefully reviewing the issue. If the item is returned in line with our policy, refunds will be processed within 5-7 business days of receiving the item. Original shipping fees are non-refundable. Refunds will be credited to the original payment method only.",
  },
  exchange: {
    title: "Exchange Policy",
    description:
      "If you received a defective or incorrect item, you may be eligible for an exchange. Contact us within 7 days of delivery to arrange an exchange. Please note that exchanges are subject to product availability, and the replacement item will be shipped upon receiving the original item in its unused condition.",
  },
  process: {
    title: "Return Process",
    description:
      "To request a return or exchange, follow these steps:\n1. Contact our customer service team with your order number, reason for return, and any necessary photos.\n2. Await approval and instructions for sending back your item.\n3. Ship the item using a traceable shipping method, as we cannot process returns for lost packages.\n4. Upon receiving and inspecting the item, we’ll notify you of the status of your return or exchange.",
  },
  questions: {
    title: "Need Assistance?",
    description: `Our support team is here to help. If you have questions about cancellations, returns, or refunds, please contact us at: <a href="mailto:support@demongears.com">support@demongears.com</a>. We’ll get back to you as soon as possible to assist with your inquiry.`,
  },
};
