import IconBtn from "./IconBtn";

export default function ConfirmationModal({ modalData }) {
  return (
    <div className="fixed inset-0 z-[1000] flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
      <div className="w-11/12 max-w-md rounded-lg bg-white shadow-lg">
        {/* Header */}
        <div className=" border-richblack-400 px-6 py-4">
          <h3 className="text-lg font-semibold text-richblack-800">
            {modalData?.text1}
          </h3>
        </div>

        {/* Body */}
        <div className="px-6 py-4">
          <p className="text-sm leading-6 text-richblack-800">
            {modalData?.text2}
          </p>
        </div>

        {/* Footer */}
        <div className="flex justify-end gap-3 border-richblack-400 px-6 py-4">
          <button
            className="rounded-md text-red-500 px-4 py-2 text-sm font-semibold  hover:text-red-600 transition duration-200"
            onClick={modalData?.btn2Handler}
          >
            {modalData?.btn2Text}
          </button>
          <IconBtn
            onclick={modalData?.btn1Handler}
            text={modalData?.btn1Text}
            customClasses="bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] text-white px-4 py-2 rounded-md"
          />
        </div>
      </div>
    </div>
  );
}
