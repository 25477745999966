import React from "react";
import LoginForm from "../components/Auth/LoginForm";
import GoogleButton from "../components/Auth/GoogleButton";

const LogIn = () => {
  return (
    <div className="bg-white flex mt-14 justify-center">
      {/* Wrapper with bg color */}
      <div className="mx-auto bg-[#f0f0f0] p-5 w-full max-w-[90%] sm:max-w-[45%] rounded-lg mt-10 mb-10">
        <h1 className="text-center text-2xl sm:text-4xl font-semibold">
          Log In
        </h1>
        <p className="text-center text-richblack-500 mt-3 text-sm sm:text-base">
          We&apos;d love to hear from you, Please fill out this form.
        </p>
        <div className="flex flex-col justify-center mt-6 w-full max-w-[80%] sm:w-2/3 mx-auto">
          <GoogleButton />
          <p className="text-center text-richblack-500 mt-6 text-sm">OR</p>

          <div className="mt-8 sm:mt-10 mx-auto w-full">
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
