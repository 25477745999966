import { toast } from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { productEndpoints } from "../apis";

const {
  CREATE_PRODUCT,
  GET_ALL_PRODUCTS,
  GET_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  PRODUCT_BY_CATEGORY,
  GET_ALL_CATEGORY_NAME,
  GET_ALL_REVIEWS,
  CREATE_RATING,
  GET_AVG_RATING,
  GET_ALL_RATING,
} = productEndpoints;

export async function getProductById(productId) {
  try {
    const response = await apiConnector("GET", GET_PRODUCT + `/${productId}`);
    if (!response.data.success) {
      throw new Error(response.data.message);
    }

    return response?.data;
  } catch (error) {
    toast.error("Something went wrong");
  }
}

export async function getAllProducts() {
  try {
    const response = await apiConnector("GET", GET_ALL_PRODUCTS);

    if (!response.data.success) {
      throw new Error(response.data.message);
    }

    return response.data;
  } catch (error) {
    toast.error("Something went wrong");
  }
}

export const addProductDetails = async (productData, token) => {
  const toastId = toast.loading("Loading...");
  let result = null;
  try {
    const response = await apiConnector("POST", CREATE_PRODUCT, productData, {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    });

    toast.success("Product Details Added Successfully");
    result = response?.data;
  } catch (error) {
    toast.error(error.message);
  }

  toast.dismiss(toastId);
  return result;
};

export const updateProduct = async (productData, token) => {
  const toastId = toast.loading("Loading...");
  let result = null;
  try {
    const response = await apiConnector("POST", UPDATE_PRODUCT, productData, {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    });

    toast.success("Product Details UPDATED Successfully");
    result = response?.data?.data;
  } catch (error) {
    toast.error(error.message);
  }

  toast.dismiss(toastId);
  return result;
};

// API call to get all category names
export async function getAllCategoryName() {
  try {
    // Call the API using the connector function
    const response = await apiConnector("GET", GET_ALL_CATEGORY_NAME);

    // Optional: Uncomment this if your API returns a success flag to verify
    if (!response?.data?.success) {
      throw new Error(
        response?.data?.message || "Failed to fetch category names"
      );
    }

    // Return the category data
    return response.data;
  } catch (error) {
    console.error("GET_ALL_CATEGORY_NAME API ERROR......", error);

    // Notify the user about the error
    toast.error("Something went wrong while fetching categories");
  }
}

export const deleteProduct = async (data, token) => {
  let result = null;
  const toastId = toast.loading("Loading...");
  try {
    const response = await apiConnector("DELETE", DELETE_PRODUCT, data, {
      Authorization: `Bearer ${token}`,
    });
    if (!response?.data?.success) {
      throw new Error("Could Not DELETE_PRODUCT");
    }
    toast.success("Product Deleted");
    result = response?.data;
  } catch (error) {
    toast.error(error.message);
  }
  toast.dismiss(toastId);
  return result;
};

export async function createRating(data, token) {
  const toastId = toast.loading("Loading...");
  try {
    const response = await apiConnector("POST", CREATE_RATING, data, {
      Authorization: `Bearer ${token}`,
    });
    toast.success("Reivew submitted successfully");
    return response?.data;
  } catch (error) {
    toast.error(error.message);
  }
  toast.dismiss(toastId);
}

export async function getAllRatings() {
  try {
    const response = await apiConnector("GET", GET_ALL_RATING);
    // if (!response.data.success) {
    //   throw new Error(response.data.message);
    // }

    return response.data;
  } catch (error) {
    toast.error("Something went wrong");
  }
}
