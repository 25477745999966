import React from "react";
import { AddProductForm } from "../UI/AddProductForm";

const AddProducts = () => {
  return (
    <div className="w-full sm:w-9/12 mx-auto px-4 sm:px-0">
      <AddProductForm />
    </div>
  );
};

export default AddProducts;
