import React from "react";
import { shippingPolicy } from "../data/shippinPolicy";

const Shipping = () => {
  const firstPolicy = shippingPolicy.find((policy) => policy.id === 1);
  const policyTwo = shippingPolicy.find((policy) => policy.id === 2);
  const policyThree = shippingPolicy.find((policy) => policy.id === 3);
  const policyFour = shippingPolicy.find((policy) => policy.id === 4);
  const policyFive = shippingPolicy.find((policy) => policy.id === 5);
  const policySix = shippingPolicy.find((policy) => policy.id === 6);
  const policySeven = shippingPolicy.find((policy) => policy.id === 7);
  const policyEight = shippingPolicy.find((policy) => policy.id === 8);

  return (
    <div className="w-full p-2 mt-16 ">
      <div className="lg:w-6/12 w-full mx-auto">
        <h2 className="text-5xl font-bold text-richblack-800 mb-16 text-center">
          Shipping Policy
        </h2>

        <div className="text-center">
          {firstPolicy && (
            <p className="mb-4 text-richblack-700 font-semibold text-xl leading-relaxed">
              {firstPolicy.description}
            </p>
          )}
        </div>

        <p className="text-left mt-8 mb-4 text-richblack-600 leading-relaxed">
          Please take a moment to review our shipping policy:
        </p>

        {/* Policy with id 2 */}
        {policyTwo && (
          <div className="mb-6 text-left">
            <h3 className="text-2xl font-semibold text-richblack-800 mb-6">
              {policyTwo.title}
            </h3>
            <p className="mt-2 text-richblack-700 font-medium text-lg leading-relaxed">
              {policyTwo.description}
            </p>
          </div>
        )}

        {/* Policy with id 3 */}
        {policyThree && (
          <div className="mb-6 text-left">
            <h3 className="text-2xl font-semibold text-richblack-800 mb-6">
              {policyThree.title}
            </h3>
            <p className="mt-2 text-richblack-700 font-medium text-lg leading-relaxed">
              {policyThree.description}
            </p>
          </div>
        )}
        <p className="text-left mt-8 mb-8 text-richblack-900 leading-relaxed font-semibold">
          We will gladly arrange for a replacement at no additional cost to you.
        </p>

        {policyFour && (
          <div className="mb-20 text-left">
            <h3 className="text-2xl font-semibold text-richblack-800 mb-6">
              {policyFour.title}
            </h3>
            <p className="mt-2 text-richblack-700 font-medium text-lg leading-relaxed">
              {policyFour.description}
            </p>
          </div>
        )}

        {policyFive && (
          <div className="mb-20 text-left">
            <h3 className="text-2xl font-semibold text-richblack-800 mb-6">
              {policyFive.title}
            </h3>
            <p className="mt-2 text-richblack-700 font-medium text-lg leading-relaxed">
              {policyFive.description}
            </p>
          </div>
        )}

        {policySix && (
          <div className="mb-20 text-left">
            <h3 className="text-2xl font-semibold text-richblack-800 mb-6">
              {policySix.title}
            </h3>
            <p className="mt-2 text-richblack-700 font-medium text-lg leading-relaxed">
              {policySix.description}
            </p>
          </div>
        )}

        {policySeven && (
          <div className="mb-20 text-left">
            {/* <h3 className="text-2xl font-semibold text-richblack-800 mb-6">{policySix.title}</h3> */}
            <p className="mt-2 text-richblack-700 font-medium text-lg leading-relaxed">
              {policySeven.description}
            </p>
          </div>
        )}

        {policyEight && (
          <div className="mb-6 text-left">
            <h3 className="text-2xl font-semibold text-richblack-800 mb-6 text-center">
              {policyEight.description}
            </h3>
            {/* <p className="mt-2 text-richblack-700 font-medium text-lg leading-relaxed">{policySix.description}</p> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Shipping;
