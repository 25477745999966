import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Input } from "@material-tailwind/react";
import { useDispatch } from "react-redux";
import { login } from "../../services/operations/auth";

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const signupHandler = () => {
    navigate("/signup");
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    dispatch(login(email, password, navigate));
    setLoading(false);
  };

  const handleOnChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <form
      className="flex flex-col gap-4 sm:gap-7 w-full mx-auto"
      onSubmit={handleSubmit}
    >
      {/* Email Input */}
      <div className="flex flex-col gap-2">
        <Input
          label="Email"
          type="email"
          name="email"
          value={email}
          onChange={handleOnChange}
          placeholder="Enter email address"
          className="text-sm sm:text-base"
        />
      </div>

      {/* Password Input */}
      <div className="flex flex-col gap-2">
        <Input
          label="Password"
          type={showPassword ? "text" : "password"}
          name="password"
          value={password}
          onChange={handleOnChange}
          required
          className="text-sm sm:text-base"
          icon={
            <div onClick={() => setShowPassword((prev) => !prev)}>
              {showPassword ? (
                <AiOutlineEyeInvisible fontSize={24} fill="#585D69" />
              ) : (
                <AiOutlineEye fontSize={24} fill="#585D69" />
              )}
            </div>
          }
        />
      </div>

      {/* Submit Button */}
      <button
        disabled={loading}
        type="submit"
        className={`w-full mx-auto rounded-md bg-gradient px-4 py-2 text-center text-xs font-bold text-white shadow-md sm:px-6 sm:py-3 sm:text-[13px] 
         ${
           !loading &&
           "transition-all duration-200 hover:scale-95 hover:shadow-none"
         } 
         disabled:bg-richblack-500`}
      >
        Log In
      </button>

      {/* Sign Up Redirect */}
      <p
        onClick={signupHandler}
        className="mx-auto font-semibold text-richred-400 underline cursor-pointer text-xs sm:text-base transition-all duration-200 hover:text-richred-600"
      >
        Don&apos;t have an account? Sign Up
      </p>
    </form>
  );
};

export default LoginForm;
