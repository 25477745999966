import React, { useEffect, useState } from "react";
import { getProductById } from "../../services/operations/Product";
import { Input, Rating, Typography } from "@material-tailwind/react";

const ProductCardCheckout = ({ product }) => {
  const [productData, setproductData] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await getProductById(product.productId);
      setproductData(response?.product);
    })();
  }, []);

  const calAvgRating = (productData) => {
    let rating = 0;
    if (
      productData?.ratingAndReviews &&
      productData?.ratingAndReviews.length > 0
    ) {
      productData?.ratingAndReviews?.forEach((item) => {
        rating += item?.rating;
      });
      rating = rating / productData?.ratingAndReviews.length;
    }
    return rating.toFixed(1);
  };

  return (
    <div
      className={`flex w-full items-center flex-wrap justify-between gap-6
        border-b border-b-richblack-400 pb-6 mt-5`}
    >
      <div className="flex flex-1 flex-col gap-4 lg:flex-row">
        <div className="w-full h-full lg:w-[30%] lg:h-[30%]">
          <img
            alt={productData?.name}
            src={productData?.images[0].images_url[0]}
            className=" rounded-lg object-cover"
          />
        </div>
        <div className="flex flex-col space-y-1 w-[65%]">
          <p className="text-xl font-bold">{productData?.name}</p>
          <p className="line-clamp-2 text-richblack-500">
            {productData?.description}
          </p>

          {/* rating section */}
          {
            // <div className="flex productData?s-center  gap-2">
            //   <span>{calAvgRating(productData)}</span>
            //   <Rating
            //     unratedColor="amber"
            //     ratedColor="amber"
            //     readonly
            //     value={Math.round(calAvgRating(productData))}
            //   />
            // </div>
          }

          {/* price section */}
          <div className="pt-3 flex space-x-3">
            <span className="text-green-600 font-semibold text-xl">
              ₹{productData?.discountedPrice}
            </span>
            <span className="text-gray-400 line-through text-lg">
              ₹{productData?.originalPrice}
            </span>
          </div>
        </div>

        <div className="w-32 ">
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-1 font-medium"
          >
            Quantity
          </Typography>
          <div className="relative w-full">
            <Input
              disabled
              type="number"
              value={product.quantity}
              className="!border-t-blue-gray-200 placeholder:text-blue-gray-300 placeholder:opacity-100  focus:!border-t-gray-900 appearance-none [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none max-w-32"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              containerProps={{
                className: "min-w-0",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardCheckout;
