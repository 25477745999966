import React from "react";
import DefaultButton from "../components/common/DefaultButton";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-screen">
      <div className="flex items-center justify-center w-1/4 mx-auto h-full flex-col gap-7">
        <p className="text-4xl font-semibold">404 - Page not found</p>
        <DefaultButton text={"Return Home"} onclick={() => navigate("/")} />
      </div>
    </div>
  );
};

export default NotFound;
