import React, { useEffect, useState } from "react";
import { ProductCard } from "../components/Product/ProductCard";
import { getProductById } from "../services/operations/Product";
import { rating, Spinner, Textarea } from "@material-tailwind/react";
import { useParams } from "react-router-dom";

import {
  Carousel,
  Button,
  IconButton,
  Rating,
  Typography,
} from "@material-tailwind/react";
import { FaCartShopping } from "react-icons/fa6";
import { addToCart, removeFromCart } from "../slices/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  FaHeart,
  FaRegHeart,
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
} from "react-icons/fa";
import { TbWeight } from "react-icons/tb";
import { RxDimensions } from "react-icons/rx";
import AddReviewModal from "../components/Product/AddReviewModal";
import { MdTexture } from "react-icons/md";

const ViewProduct = () => {
  const [productData, setProductData] = useState(null);
  const [rated, setRated] = React.useState(0);
  const [reviewStars, setReviewStars] = useState(0);
  const { cart } = useSelector((state) => state.cart);
  const [loading, setloading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const { productId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProduct = async () => {
      setloading(true);
      const response = await getProductById(productId);
      setProductData(response?.product);

      setloading(false);
    };
    fetchProduct();
  }, [productId]);

  useEffect(() => {
    setSelectedProduct(productData?.images[0]);
    let totalRating = 0;
    if (productData?.ratingAndReviews.length > 0) {
      productData?.ratingAndReviews?.forEach((item) => {
        totalRating += item?.rating;
      });
      setRated(
        (totalRating / productData?.ratingAndReviews?.length).toFixed(1)
      );
      setReviewStars(Math.round(rated));
    }
  }, [productData]);

  const checkCart = () => {
    let isPresent = false;
    if (!cart) {
      return isPresent;
    }

    cart.forEach((item) => {
      if (item.productId === productData?._id) {
        isPresent = true;
      }
    });
    return isPresent;
  };

  const handleColorChange = (colorData) => {
    setSelectedProduct(colorData);
  };

  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Spinner color="red" className="h-10 w-10" />
      </div>
    );
  }

  return (
    <div className="w-11/12 mx-auto mt-20">
      <div className="flex w-full gap-3 flex-col md:flex-row">
        <div className="md:w-1/2 p-5">
          <Carousel
            loop={true}
            transition={{ type: "spring", duration: 0.7 }}
            className="rounded-xl h-[85%]"
            navigation={({ setActiveIndex, activeIndex, length }) => (
              <div className="absolute bottom-2 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                {new Array(length).fill("").map((_, i) => (
                  <span
                    key={i}
                    className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                      activeIndex === i
                        ? "w-8 bg-richblack-25"
                        : "w-4 bg-richblack-400"
                    }`}
                    onClick={() => setActiveIndex(i)}
                  />
                ))}
              </div>
            )}
            prevArrow={({ handlePrev }) => (
              <IconButton
                variant="text"
                color="white"
                size="lg"
                onClick={handlePrev}
                className="!absolute top-2/4 left-4 -translate-y-2/4"
              >
                <FaLongArrowAltLeft className="text-2xl text-richblack-200" />
              </IconButton>
            )}
            nextArrow={({ handleNext }) => (
              <IconButton
                variant="text"
                color="white"
                size="lg"
                onClick={handleNext}
                className="!absolute top-2/4 !right-4 -translate-y-2/4"
              >
                <FaLongArrowAltRight className="text-2xl text-richblack-200" />
              </IconButton>
            )}
          >
            {selectedProduct &&
              selectedProduct?.images_url?.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={`image ${index}`}
                  className="w-full h-full object-cover"
                />
              ))}
          </Carousel>
        </div>
        <div className="p-5 w-full md:w-[50%] mt-5 ">
          <div className="mb-4">
            {/* <img alt="logo" src="" /> */}
            <p className="text-sm ">DemonGears</p>
          </div>

          <h1 className="text-4xl font-bold ">{productData?.name}</h1>
          {/* ratings  */}
          {reviewStars > 0 && (
            <div className="flex items-center  gap-2 font-bold text-blue-gray-500 mt-1">
              <p className="text-lg">{rated}</p>
              <Rating
                value={reviewStars}
                readonly
                unratedColor="amber"
                ratedColor="amber"
              />
              <Typography
                color="blue-gray"
                className="font-medium text-blue-gray-500"
              >
                Based on {productData?.ratingAndReviews.length} Reviews
              </Typography>
            </div>
          )}

          <div className="flex  items-center  mt-5">
            <p className="font-semibold mr-4 text-2xl text-green-600">
              ₹{productData?.discountedPrice}
            </p>
            <p className="line-through mr-3 text-2xl text-richblack-400">
              ₹{productData?.originalPrice}
            </p>
            <p className="text-sm text-red-500 ">
              {100 -
                Math.floor(
                  (productData?.discountedPrice / productData?.originalPrice) *
                    100
                )}
              % off
            </p>
          </div>
          <div className="mt-4 ">
            <h3 className="text-richblack-400 mb-2">Description</h3>
            <p className="">{productData?.description}</p>
          </div>

          {/* color container */}
          <div className="mt-7 w-full">
            <h3 className="text-sm text-richblack-400">Color Options:</h3>

            <div className="flex gap-5 items-center mt-2">
              {productData?.images?.map((item, index) => (
                <div key={index}>
                  <Button
                    className="w-20 flex items-center justify-center"
                    onClick={() => handleColorChange(item)}
                    variant={
                      selectedProduct?.color === item.color
                        ? "gradient"
                        : "outlined"
                    }
                  >
                    {item.color}{" "}
                  </Button>
                </div>
              ))}
            </div>
          </div>

          <div className="flex gap-3 mt-7 items-center">
            <Button
              variant={checkCart() ? "outlined" : "filled"}
              className="flex items-center gap-3 justify-center transition-all duration-300"
              onClick={() => {
                if (checkCart()) {
                  dispatch(removeFromCart(productData._id));
                } else {
                  const data = {
                    productId: productData._id,
                    color: selectedProduct.color,
                  };
                  dispatch(addToCart(data));
                }
              }}
              size="lg"
              color="red"
              fullWidth
            >
              <FaCartShopping />
              {checkCart() ? "Remove From Cart" : "Add To Cart"}
            </Button>
          </div>

          {/* detials */}
          <div className="rounded-xl p-5 mt-7">
            <div className="flex flex-wrap sm:flex-nowrap justify-between w-[80%] mx-auto gap-4">
              <div className="flex flex-col items-center justify-center w-full sm:w-[30%] gap-2 bg-red-50 p-2 rounded-lg">
                <p className="font-body text-red-500">Dimensions</p>
                <RxDimensions className="text-xl" />
                <p>{productData?.productFeatures?.dimensions} cm</p>
              </div>

              <div className="flex flex-col items-center justify-center w-full sm:w-[30%] gap-2 bg-red-50 p-2 rounded-lg">
                <p className="font-body text-red-500">Material</p>
                <MdTexture className="text-xl" />
                <p>{productData?.productFeatures?.material}</p>
              </div>

              <div className="flex flex-col items-center justify-center w-full sm:w-[30%] gap-2 bg-red-50 p-2 rounded-lg">
                <p className="font-body text-red-500">Weight</p>
                <TbWeight className="text-xl" />
                <p>{productData?.productFeatures?.weight} kg</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-10 w-full sm:w-1/2 ml-5 px-4">
        <div className="w-full">
          <h2 className="text-2xl sm:text-3xl font-bold text-center sm:text-left">
            Rating and Reviews
          </h2>
          <div className="mt-5 flex flex-col sm:flex-row gap-3 items-center">
            <p className="text-lg text-center sm:text-left ring-richblack-100">
              Want to Give Feedback?
            </p>
            <AddReviewModal productId={productId} />
          </div>
          <div className="mt-5">
            {productData?.ratingAndReviews?.length ? (
              <div className="flex flex-col gap-5">
                {productData?.ratingAndReviews?.map((item) => (
                  <div className="p-4 bg-red-50 rounded-xl" key={item._id}>
                    <div className="font-bold capitalize text-lg flex justify-between items-start">
                      <h3>
                        {item.user.firstName} {item.user.lastName}
                      </h3>
                      <span className="flex flex-col items-end">
                        <Rating
                          value={Math.round(item.rating)}
                          readonly
                          unratedColor="amber"
                          ratedColor="amber"
                        />
                      </span>
                    </div>
                    <p className="text-md mt-2">{item.review}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex items-center justify-center mt-7 text-richblack-200">
                <p>No Reviews Yet! Be the first to Review</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProduct;
