import { Button } from "@material-tailwind/react";

export default function DefaultButton({
  text,
  onclick,
  children,
  disabled,
  outline = false,
  customClasses,
  type,
}) {
  return (
    <Button
      variant="gradient"
      // color="red"
      disabled={disabled}
      onClick={onclick}
      className={`flex items-center justify-center w-full sm:w-auto sm:px-4 py-2 ${
        outline
          ? "border border-black"
          : "bg-gradient-to-r from-richred-500 to-black text-white"
      } ${customClasses}`}
      type={type}
    >
      {children ? (
        <>
          {text}
          {children}
        </>
      ) : (
        text
      )}
    </Button>
  );
}
