import React, { useEffect } from "react";
import { Spinner } from "@material-tailwind/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../slices/authSlice";

const Validating = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  useEffect(() => {
    localStorage.setItem("token", JSON.stringify(token));
    dispatch(setToken(token));
    navigate("/profile/settings");
  }, [token]);

  return (
    <div className="flex flex-col w-full h-screen items-center justify-center">
      <Spinner color="red" className="h-10 w-10" />
    </div>
  );
};

export default Validating;
