import React, { useEffect, useState } from "react";
import { DefaultSidebar } from "../components/UI/DefaultSidebar";
import { useDispatch, useSelector } from "react-redux";
import { ComplexNavbar } from "../components/UI/profileMenuItems";
import { Outlet } from "react-router-dom";
import { getAllUserData } from "../services/operations/profileAPI";
import { setUser } from "../slices/profileSlice";

const Profile = () => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (token) {
          const response = await getAllUserData(token);
          setUserData(response.userDetails);
          dispatch(setUser(response?.userDetails));
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };
    fetchUserData();
  }, [token]);

  return (
    <div>
      {userData?.role === "admin" ? (
        <div className="relative flex min-h-[calc(100vh-3.5rem)]">
          <DefaultSidebar />
          <div className="h-[calc(100vh-3.5rem)] flex-1 overflow-auto">
            <div className="mx-auto w-11/12 max-w-[1200px] py-10 mt-16">
              <Outlet />
            </div>
          </div>
        </div>
      ) : userData?.role === "user" ? (
        <div className="relative flex min-h-[calc(100vh-3.5rem)] flex-col mt-16">
          <ComplexNavbar />
          <div className="flex-1 p-2 mx-auto w-11/12 max-w-[1000px]">
            <Outlet />
          </div>
        </div>
      ) : (
        <div className="text-center p-10">
          <p>No valid role found for the user.</p>
        </div>
      )}
    </div>
  );
};

export default Profile;
