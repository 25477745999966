import React, { useState } from "react";
import { CogIcon, BuildingLibraryIcon } from "@heroicons/react/24/outline";
import { FcProcess } from "react-icons/fc";

export function StepperWithContent({ order }) {
  const [activeStep, setActiveStep] = useState(0);

  const steps = ["Processing", "Shipped", "Delivered"];
  const icons = [FcProcess, CogIcon, BuildingLibraryIcon];

  const { statusHistory } = order;

  const statusMap = statusHistory.reduce((acc, status) => {
    acc[status.status] = status.timestamp;
    return acc;
  }, {});

  return (
    <div className="w-full px-8 py-6 flex flex-col gap-16 items-center sm:flex-row sm:gap-2">
      {steps.map((step, index) => {
        const IconComponent = icons[index % icons.length];
        const hasTimestamp = Boolean(statusMap[step]); // Check if timestamp exists
        const color = hasTimestamp ? "text-green-500" : "text-gray-500"; // Set color based on timestamp

        const timestamp = hasTimestamp
          ? new Date(statusMap[step]).toLocaleString()
          : "Not Available";

        // Determine the color of the line
        const nextStep = steps[index + 1];
        const hasNextTimestamp = nextStep
          ? Boolean(statusMap[nextStep])
          : false;
        const lineColor =
          hasTimestamp && hasNextTimestamp ? "bg-green-500" : "bg-gray-300";

        return (
          <React.Fragment key={index}>
            {/* Step */}
            <div
              className="flex flex-col items-center cursor-pointer "
              onClick={() => setActiveStep(index)}
            >
              <div
                className={`flex items-center justify-center w-12 h-12 rounded-full border-2 ${
                  hasTimestamp ? "border-green-500" : "border-gray-300"
                }`}
              >
                <IconComponent className={`h-6 w-6 ${color}`} />
              </div>
              <div className="mt-2 text-center">
                <p className={`font-semibold ${color}`}>{step}</p>
                <p className={`text-sm ${color}`}>{timestamp}</p>
              </div>
            </div>

            {/* Line between steps */}
            {index < steps.length - 1 && (
              <div
                className={`flex-grow w-1 mx-2 -mt-14 -mb-14 h-[80px] sm:h-1 sm:min-w-[50px] ${lineColor}`}
              ></div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}
