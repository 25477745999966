import { RiEditBoxLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { formattedDate } from "../../utils/dateFormatter";
import IconBtn from "../common/IconBtn";

export default function Settings() {
  const { user } = useSelector((state) => state.profile);
  const navigate = useNavigate();

  return (
    <>
      <h1 className="mb-6 mt-4 text-2xl font-bold text-richblack-800 sm:mb-14 sm:mt-6 sm:text-3xl">
        My Profile
      </h1>

      {/* Section 1 */}
      <div className="flex flex-wrap items-center justify-between gap-y-4 rounded-md border-[1px] border-richblack-700 bg-red-100 p-4 px-6 sm:flex-nowrap sm:p-8 sm:px-12">
        <div className="flex items-center gap-x-4">
          <img
            src={user?.image}
            alt={`profile-${user?.firstName}`}
            className="aspect-square w-[50px] rounded-full object-cover sm:w-[78px]"
          />
          <div className="space-y-1">
            <p className="text-base font-semibold text-richblack-800 sm:text-lg">
              {user?.firstName + " " + user?.lastName}
            </p>
            <p className="text-sm text-richblack-400">{user?.email}</p>
          </div>
        </div>
        <IconBtn
          text="Edit"
          onclick={() => {
            navigate("/profile/settings/edit");
          }}
        >
          <RiEditBoxLine />
        </IconBtn>
      </div>

      {/* Section 2 */}
      <div className="my-6 flex flex-col gap-y-6 rounded-md border-[1px] border-richblack-700 bg-red-100 p-4 px-6 sm:my-10 sm:gap-y-10 sm:p-8 sm:px-12">
        <div className="flex w-full items-center justify-between">
          <p className="text-base font-semibold text-richblack-800 sm:text-lg">
            About
          </p>
        </div>
        <p
          className={`${
            user?.additionalDetails?.about
              ? "text-richblack-800"
              : "text-richblack-400"
          } text-sm font-medium`}
        >
          {user?.additionalDetails?.about ?? "Write Something About Yourself"}
        </p>
      </div>

      {/* Section 3 */}
      <div className="my-6 flex flex-col gap-y-6 rounded-md border-[1px] border-richblack-700 bg-red-100 p-4 px-6 sm:my-10 sm:gap-y-10 sm:p-8 sm:px-12">
        <div className="flex w-full items-center justify-between">
          <p className="text-base font-semibold text-richblack-800 sm:text-lg">
            Personal Details
          </p>
        </div>
        <div className="flex flex-wrap gap-4 sm:max-w-[500px] sm:flex-nowrap">
          <div className="flex flex-col gap-y-5 w-full sm:w-auto">
            <div>
              <p className="mb-2 text-sm text-richblack-800">First Name</p>
              <p className="text-sm font-medium text-richblack-400">
                {user?.firstName}
              </p>
            </div>
            <div>
              <p className="mb-2 text-sm text-richblack-800">Email</p>
              <p className="text-sm font-medium text-richblack-400">
                {user?.email}
              </p>
            </div>
            <div>
              <p className="mb-2 text-sm text-richblack-800">Gender</p>
              <p className="text-sm font-medium text-richblack-400">
                {user?.additionalDetails?.gender ?? "Add Gender"}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-y-5 w-full sm:w-auto">
            <div>
              <p className="mb-2 text-sm text-richblack-800">Last Name</p>
              <p className="text-sm font-medium text-richblack-400">
                {user?.lastName}
              </p>
            </div>
            <div>
              <p className="mb-2 text-sm text-richblack-800">Phone Number</p>
              <p className="text-sm font-medium text-richblack-400">
                {user?.additionalDetails?.contactNumber ?? "Add Contact Number"}
              </p>
            </div>
            <div>
              <p className="mb-2 text-sm text-richblack-800">Date Of Birth</p>
              <p className="text-sm font-medium text-richblack-400">
                {formattedDate(user?.additionalDetails?.dateOfBirth) ??
                  "Add Date Of Birth"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
