import React, { useState } from "react";
import { FooterLink2 } from "../../data/footer-links";
import { Link } from "react-router-dom";
import logo from "../../assets/Home/logo.png";
import { FaFacebook, FaGoogle, FaTwitter, FaYoutube } from "react-icons/fa";
import HighlightText from "./HighlightText";
import DefaultButton from "../common/DefaultButton";
import { addNewSubscriber } from "../../services/operations/subscribeAPI";

const BottomFooter = [
  "Privacy Policy",
  // "Cookie Policy",
  "Terms and Conditions",
];
const quickLinks = ["Home", "About us", "Products", "Cart"];
const customerService = [
  "FAQ",
  "Contact Us",
  "Shipping Policy",
  "Return Policy",
];

const Footer = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOnChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = async () => {
    setLoading(true);
    if (!email.trim()) {
      setError("Please enter your email first");
      return;
    }

    try {
      const response = await addNewSubscriber(email);
      setEmail("");
      setError(response.message);
    } catch (error) {
      console.error("Error subscribing user", error);
      setError("Failed to subscribe. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // const handleSubscribe = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const response = await axios.post("/api/addNewSubscriber", { email });

  //     if (response.status === 201) {
  //       alert("Thank you for subscribing!");
  //       setEmail(""); // Clear the input field on success
  //     } else {
  //       alert("An error occurred. Please try again.");
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.status === 409) {
  //       alert("This email is already subscribed.");
  //     } else {
  //       alert("Failed to subscribe. Please check your internet connection or try again later.");
  //     }
  //   }
  // }
  return (
    <div className="bg-richblack-800 mt-16">
      <div className="flex lg:flex-row gap-8 items-center justify-between w-11/12 max-w-maxContent text-richblack-400 leading-6 mx-auto relative py-14">
        <div className="border-b w-[100%] flex flex-col lg:flex-row pb-5 border-richblack-700">
          {/* Section 1 */}
          <div className="lg:w-[65%] flex flex-row justify-between lg:border-r lg:border-richblack-700 pl-3 lg:pr-5 gap-3">
            <div className="w-[23%] flex flex-col gap-3 mb-7 lg:pl-0">
              <div className="relative flex items-center justify-center w-28 h-10 bg-richblack-100 rounded-full">
                <img
                  src={logo}
                  alt="LOGO"
                  className="object-contain w-28 h-28"
                />
              </div>

              <h1 className="text-richblack-50 font-semibold text-[16px]">
                Company
              </h1>
              <div className="flex flex-col gap-2">
                {["About us", "Privacy Policy", "Terms and Conditions"].map(
                  (ele, i) => (
                    <div
                      key={i}
                      className="text-[14px] cursor-pointer hover:text-richblack-50 transition-all duration-200"
                    >
                      <Link to={ele.split(" ").join("-").toLowerCase()}>
                        {ele}
                      </Link>
                    </div>
                  )
                )}
              </div>
              <div className="flex gap-3 mt-10">
                <a
                  // href="https://www.facebook.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook className="text-xl sm:text-2xl md:text-3xl" />
                </a>
                <a
                  // href="https://www.google.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaGoogle className="text-xl sm:text-2xl md:text-3xl" />
                </a>
                <a
                  // href="https://www.twitter.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter className="text-xl sm:text-2xl md:text-3xl" />
                </a>
                <a
                  // href="https://www.youtube.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube className="text-xl sm:text-2xl md:text-3xl" />
                </a>
              </div>
            </div>

            <div className="w-[23%] mb-7 lg:pl-0">
              <h1 className="text-richblack-50 font-semibold text-[16px]">
                Quick Links
              </h1>
              <div className="flex flex-col gap-2 mt-2">
                {quickLinks.map((ele, index) => (
                  <div
                    key={index}
                    className="text-[14px] cursor-pointer hover:text-richblack-50 transition-all duration-200"
                  >
                    <Link
                      to={
                        ele === "Home"
                          ? "/"
                          : ele.split(" ").join("-").toLowerCase()
                      }
                    >
                      {ele}
                    </Link>
                  </div>
                ))}
              </div>
            </div>

            <div className="w-[23%] mb-7 lg:pl-0">
              <h1 className="text-richblack-50 font-semibold text-[16px]">
                Customer Service
              </h1>
              <div className="flex flex-col gap-2 mt-2">
                {customerService.map((ele, index) => (
                  <div
                    key={index}
                    className="text-[14px] cursor-pointer hover:text-richblack-50 transition-all duration-200"
                  >
                    <Link to={ele.split(" ").join("-").toLowerCase()}>
                      {ele}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Section 2 */}
          <div className="lg:w-[35%] flex flex-wrap flex-row justify-between pl-3 lg:pl-5 gap-3">
            <div className="px-4 space-y-4">
              <h4 className="text-gradient bg-clip-text text-3xl font-semibold text-transparent lg:w-[70%]">
                Subscribe us
              </h4>
              <p>
                <HighlightText
                  text={
                    "Sign up now and get news about our exclusive offers & latest launches."
                  }
                />
              </p>
              <form
                className="flex items-center space-x-2"
                onSubmit={(e) => e.preventDefault()} // Prevent form submission refresh
              >
                <input
                  required
                  type="text"
                  name="email"
                  value={email}
                  onChange={handleOnChange}
                  placeholder="Enter email address"
                  style={{
                    boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                  }}
                  className="w-[80%] rounded-[0.5rem] bg-richblack-600 p-[12px] text-richblack-5"
                />
                <button
                  onClick={handleSubscribe}
                  type="button" // You can change this to "submit" if it's part of a form
                  disabled={loading} // This disables the button when `loading` is true
                  className={`w-[25%] sm:w-[20%] rounded-md bg-gradient justify-center py-2 text-center text-xs font-bold text-white shadow-md sm:py-3 sm:text-[16px] 
                    ${
                      !loading &&
                      "transition-all duration-200 hover:scale-95 hover:shadow-none"
                    }
                    disabled:bg-richblack-500`}
                >
                  Subscribe
                </button>
              </form>

              {error && (
                <span className="-mt-1 text-[12px] text-yellow-100">
                  {error}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row items-center justify-between w-11/12 max-w-maxContent text-richblack-400 mx-auto  pb-14 text-sm">
        {/* Section 1 */}
        <div className="flex justify-between lg:items-start items-center flex-col lg:flex-row gap-3 w-full">
          <div className="flex flex-row">
            {BottomFooter.map((ele, i) => {
              return (
                <div
                  key={i}
                  className={` ${
                    BottomFooter.length - 1 === i
                      ? ""
                      : "border-r border-richblack-700 cursor-pointer hover:text-richblack-50 transition-all duration-200"
                  } px-3 `}
                >
                  <Link to={ele.split(" ").join("-").toLocaleLowerCase()}>
                    {ele}
                  </Link>
                </div>
              );
            })}
          </div>

          <div className="text-center">❤️ DemonGears © 2024 </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
