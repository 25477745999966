import React, { useState } from "react";
import { Input, Textarea, Button } from "@material-tailwind/react";
import { createFeedback } from "../../services/operations/FeedbackAPI";

const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { name, email, phone, message } = formData;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createFeedback(formData); // Call the API
      setSuccessMessage("Your Response Has Been Submitted!"); // Show success message
      setErrorMessage(""); // Clear error message if any
      setFormData({ name: "", email: "", phone: "", message: "" }); // Clear the form
    } catch (error) {
      console.error("Error submitting feedback:", error);
      setErrorMessage("Failed to submit your response. Please try again.");
      setSuccessMessage(""); // Clear success message if any
    }
  };

  return (
    <form
      className="flex flex-col gap-6 w-full mx-auto p-4 lg:p-6 border border-gray-300 rounded-lg shadow-md max-w-md"
      onSubmit={handleOnSubmit}
    >
      <h2 className="text-2xl lg:text-3xl font-semibold text-left text-gradient bg-clip-text text-transparent bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045]">
        Contact Us
      </h2>

      <p className="text-xs lg:text-sm text-richblack-500">
        Your email address will not be published. Required fields are marked.
      </p>

      {/* Success/Failure Messages */}
      {successMessage && (
        <p className="text-green-600 text-sm">{successMessage}</p>
      )}
      {errorMessage && <p className="text-red-600 text-sm">{errorMessage}</p>}

      {/* Name Field */}
      <div className="flex flex-col gap-2">
        <Input
          label="Name"
          type="text"
          name="name"
          value={name}
          onChange={handleOnChange}
          placeholder="Enter your full name"
          className="w-full"
          required
        />
      </div>

      {/* Email Field */}
      <div className="flex flex-col gap-2">
        <Input
          label="Email Address"
          type="email"
          name="email"
          value={email}
          onChange={handleOnChange}
          placeholder="Enter your email address"
          className="w-full"
          required
        />
      </div>

      {/* Phone Field */}
      <div className="flex flex-col gap-2">
        <Input
          label="Phone Number"
          type="tel"
          name="phone"
          value={phone}
          onChange={handleOnChange}
          placeholder="Enter your 10-digit phone number"
          className="w-full"
          pattern="[0-9]{10}"
          required
        />
      </div>

      {/* Message Field */}
      <div className="flex flex-col gap-2">
        <Textarea
          label="Message"
          name="message"
          value={message}
          onChange={handleOnChange}
          className="w-full"
          rows="4"
          required
        />
      </div>

      {/* Submit Button */}
      <div className="flex justify-start">
        <button
          type="submit"
          className="w-1/3 lg:w-[20%] py-3 rounded-md bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] text-white font-bold text-center transition-transform duration-200 hover:scale-95 text-sm lg:text-base"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default ContactUsForm;
