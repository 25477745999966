import React from "react";
import { Button } from "@material-tailwind/react";
import { FcGoogle } from "react-icons/fc";

const GoogleButton = () => {
  const googleLogin = () => {
    window.location.href = "https://api.demongears.com/api/v1/auth/google";
  };
  return (
    <Button
      size="md"
      variant="outlined"
      color="blue-gray"
      className="flex items-center gap-3 justify-center w-full"
      onClick={googleLogin}
    >
      <FcGoogle className="h-5 w-5 sm:h-6 sm:w-6" />
      <span className="text-xs sm:text-base">Continue with Google</span>
    </Button>
  );
};

export default GoogleButton;
