import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductList from "../components/Cart/ProductList";
import AmountBuyBox from "../components/Cart/AmountBuyBox";
import { Button, Spinner } from "@material-tailwind/react";
import { MdDeleteOutline } from "react-icons/md";
import { resetCart } from "../slices/cartSlice";
import { getProductById } from "../services/operations/Product";
import CartProductCard from "../components/Cart/CartProductCard";
import DefaultButton from "../components/common/DefaultButton";
import { useNavigate } from "react-router-dom";

const Cart = () => {
  const { cart, totalItems } = useSelector((state) => state.cart);
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [originalPrice, setOriginalPrice] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchAllProduct = async () => {
      if (cart) {
        setAllProducts([]);
        setLoading(true);

        let totalDiscountedPrice = 0;
        let totalOriginalPrice = 0;
        const allProductsArray = [];

        for (const item of cart) {
          const response = await getProductById(item.productId);
          if (response?.product) {
            totalDiscountedPrice +=
              response?.product?.discountedPrice * item.quantity;
            totalOriginalPrice +=
              response?.product?.originalPrice * item.quantity;
            allProductsArray.push({
              product: response?.product,
              quantity: item?.quantity,
            });
          }
        }

        // After all products are fetched, update the state
        setDiscountedPrice(totalDiscountedPrice);
        setOriginalPrice(totalOriginalPrice);
        setAllProducts(allProductsArray);
        setLoading(false);
      }
    };

    fetchAllProduct();
  }, [cart, totalItems]); // Make sure your dependencies are accurate

  return (
    <div className="w-11/12 mx-auto h-screen mt-20 ">
      <div className="lg:w-10/12 md:w-full mx-auto">
        <h2 className="text-4xl font-bold text-gradient mb-8 text-center">
          Your Cart
        </h2>
      </div>

      <div className="w-[80%] mx-auto">
        <div className="flex justify-between border-b border-b-richblack-400">
          <p className=" pt-2 font-semibold text-richblack-400">
            {totalItems} Product<span>{totalItems > 1 && "s"}</span> in cart
          </p>
          <div className="mb-2 pr-2">
            {totalItems > 0 && (
              <Button
                className="flex gap-2 items-center justify-center"
                variant="outlined"
                color="red"
                onClick={() => dispatch(resetCart())}
              >
                <MdDeleteOutline className="text-[18px]" />
                Remove All
              </Button>
            )}
          </div>
        </div>

        {totalItems > 0 ? (
          loading ? (
            <div className="w-full h-[70vh] flex items-center justify-center">
              <Spinner color="red" className="h-10 w-10" />
            </div>
          ) : (
            <div className="mt-8 flex flex-col-reverse items-start gap-x-10 gap-y-6 lg:flex-row">
              <div className="mt-5 w-[70%]">
                {allProducts.map((item, index) => (
                  <CartProductCard item={item} index={index} key={index} />
                ))}
              </div>
              <AmountBuyBox
                discountPrice={discountedPrice}
                originalPrice={originalPrice}
              />
            </div>
          )
        ) : (
          <div className="mt-14 flex flex-col items-center justify-center">
            <p className=" text-center text-3xl text-richblack-200">
              Your Cart is Empty
            </p>
            <div className="w-60 mt-16 flex items-center justify-center">
              <DefaultButton onclick={() => navigate("/products")}>
                Explore Products
              </DefaultButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
