import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { buyProducts } from "../services/operations/Payment";
import { useNavigate } from "react-router-dom";
import { setUser } from "../slices/profileSlice";
import {
  Radio,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
  CardBody,
  CardFooter,
  Button,
  Spinner,
} from "@material-tailwind/react";
import { DrawerPlacement } from "../components/UI/DrawerPlacement";
import { getAllUserData } from "../services/operations/profileAPI";
import ProductCardCheckout from "../components/Checkout/ProductCardCheckout";
import { getProductById } from "../services/operations/Product";
import DefaultButton from "../components/common/DefaultButton";

const Checkout = () => {
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);
  const { cart, totalItems } = useSelector((state) => state.cart);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedAddressId, setSelectedAddressId] = useState("");

  useEffect(() => {
    const fetchAllProduct = async () => {
      if (cart) {
        setLoading(true);

        let totalDiscountedPrice = 0;
        let totalOriginalPrice = 0;
        const allProductsArray = [];

        for (const item of cart) {
          const response = await getProductById(item.productId);
          if (response?.product) {
            totalDiscountedPrice +=
              response?.product?.discountedPrice * item.quantity;
            totalOriginalPrice +=
              response?.product?.originalPrice * item.quantity;
            allProductsArray.push({
              product: response?.product,
              quantity: item?.quantity,
            });
          }
        }

        // After all products are fetched, update the state
        setDiscountedPrice(totalDiscountedPrice);
        setOriginalPrice(totalOriginalPrice);
        setLoading(false);
      }
    };

    fetchAllProduct();
  }, [cart, totalItems]); // Make sure your dependencies are accurate

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        if (token) {
          await getAllUserData(token, dispatch);
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      }
      setLoading(false);
    };

    fetchUserData();
  }, [token]);

  useEffect(() => {
    if (user?.addresses?.length >= 0) {
      setSelectedAddressId(user?.addresses[0]?._id);
    }
  }, [user]);

  const handleCheckout = async () => {
    const orderData = { products: cart, shippingId: selectedAddressId };
    await buyProducts({
      token,
      orderData,
      navigate,
      dispatch,
      userDetails: user,
    });
  };

  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Spinner color="red" className="h-10 w-10" />
      </div>
    );
  }

  return (
    <div className="w-5/6 mx-auto min-h-screen mt-20 ">
      <div className="lg:w-10/12 md:w-full mx-auto">
        <h1 className="text-4xl font-bold text-gradient mb-8 text-center">
          Checkout
        </h1>
      </div>

      <div className="mt-10">
        <h3 className="text-2xl font-semibold ">Select your Address</h3>

        <div>
          {user?.addresses?.length > 0 && (
            <div>
              {" "}
              <Card className="w-full " shadow={false}>
                <List className="grid md:grid-cols-2 grid-cols-1 xl:grid-cols-3">
                  {user?.addresses?.map((item) => (
                    <ListItem key={item?._id} className="p-0">
                      <label
                        htmlFor="horizontal-list-react"
                        className="flex cursor-pointer items-center px-3 py-2"
                      >
                        <ListItemPrefix className="mr-3">
                          <Radio
                            defaultChecked={true}
                            name="description"
                            label={
                              <Card
                                color="grey"
                                variant="filled"
                                className="mt-6 w-full "
                              >
                                <CardBody>
                                  <Typography
                                    variant="h5"
                                    color="blue-gray"
                                    className="mb-2"
                                  >
                                    {item.firstName + " " + item.lastName}
                                  </Typography>
                                  <Typography>{item.address1}</Typography>
                                  <Typography>{item.address2}</Typography>
                                  <Typography>{item.city}</Typography>
                                  <Typography>{item.country}</Typography>
                                  <Typography>{item.zipCode}</Typography>
                                  <Typography>{item.contactNumber}</Typography>
                                </CardBody>
                              </Card>
                            }
                            onChange={() => setSelectedAddressId(item._id)}
                            containerProps={{
                              className: "-mt-5",
                            }}
                          />
                        </ListItemPrefix>
                      </label>
                    </ListItem>
                  ))}
                </List>
              </Card>
            </div>
          )}

          <div className="mt-5 ">
            {user?.addresses?.length < 7 && (
              <DrawerPlacement setUser={(user) => dispatch(setUser(user))} />
            )}
          </div>
        </div>
      </div>

      <div className="mt-10  w-full  ">
        <h3 className="text-2xl font-semibold">Products</h3>
        <div className=" flex gap-16 flex-col md:flex-row">
          <div className="md:w-3/4 w-full mt-5 flex flex-col">
            {cart.map((item) => (
              <ProductCardCheckout product={item} key={item._id} />
            ))}
          </div>

          <div className="p-5 mb-5 rounded-lg bg-richblack-5 md:w-1/4 w-full h-full">
            <p className="text-lg mb-3 text-gradient font-bold">
              Complete Your Order
            </p>

            <div className="flex flex-col gap-1">
              <div className="flex justify-between ">
                <p>Items:</p>
                <p>₹{originalPrice}</p>
              </div>
              <div className="flex justify-between ">
                <p>Discount:</p>
                <p>-₹{originalPrice - discountedPrice}</p>
              </div>
              <div className="flex justify-between ">
                <p>Delivery:</p>
                <p>₹{49}</p>
              </div>
              <div className="flex justify-between ">
                <p>Total:</p>
                <p>₹{discountedPrice + 49}</p>
              </div>
            </div>

            <div className="mt-2">
              <DefaultButton
                customClasses={`font-bold`}
                onclick={handleCheckout}
              >
                Pay now
              </DefaultButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
