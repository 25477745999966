import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Typography,
  CardBody,
} from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import { updateOrderStatusApi } from "../../services/operations/order";

const TABLE_HEAD = ["User", "Order ID", "Total Price", "Status", "Action"];

export function OrdersTable({ recentOrders, setRecentOrders }) {
  const [statusFilter, setStatusFilter] = useState("All");
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate(); // Initialize useNavigate

  // Filter orders based on selected status
  const filteredOrders =
    statusFilter === "All"
      ? recentOrders
      : recentOrders.filter((order) => order.orderStatus === statusFilter);

  const updateOrderStatus = async (token, orderId, orderStatus) => {
    try {
      const result = await updateOrderStatusApi(token, orderId, orderStatus);

      setRecentOrders(result.orders);
    } catch (error) {
      console.error("Failed to update order status", error);
      alert("Failed to update order status. Please try again.");
    }
  };

  // Sort orders by the 'createdAt' date in descending order
  const sortedOrders = filteredOrders?.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-8 flex items-center justify-between gap-8">
          <div>
            <Typography variant="h5" color="blue-gray">
              Orders List
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              Manage and update order statuses for Demongears.
            </Typography>
          </div>
          <div>
            {/* Status filter dropdown */}
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className="border border-blue-gray-100 p-2 rounded-md"
            >
              <option value="All">All</option>
              <option value="Processing">Processing</option>
              <option value="Shipped">Shipped</option>
              <option value="Delivered">Delivered</option>
              <option value="Cancelled">Cancelled</option>
            </select>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <table className="mt-4 w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedOrders && sortedOrders.length > 0 ? (
              sortedOrders.map((order, index) => {
                const isLast = index === sortedOrders.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";
                const fullName = `${order.user.firstName} ${order.user.lastName}`;

                return (
                  <tr
                    key={order._id}
                    onClick={() =>
                      navigate(`/profile/recent-orders/${order._id}`)
                    } // Navigate on row click
                    className="cursor-pointer hover:bg-blue-gray-50"
                  >
                    {/* User details */}
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {fullName}
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal opacity-70"
                          >
                            {order.user.email}
                          </Typography>
                        </div>
                      </div>
                    </td>

                    {/* Order ID */}
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {order._id}
                      </Typography>
                    </td>

                    {/* Total price */}
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        ₹ {order.totalAmount}
                      </Typography>
                    </td>

                    {/* Status */}
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {order.orderStatus}
                      </Typography>
                    </td>

                    {/* Action to change status */}
                    <td className={classes}>
                      <div className="flex items-center gap-2">
                        <select
                          value={order.orderStatus}
                          onChange={(e) =>
                            updateOrderStatus(token, order._id, e.target.value)
                          }
                          className="border border-blue-gray-100 p-2 rounded-md"
                          onClick={(e) => e.stopPropagation()} // Prevent row click when changing status
                        >
                          <option value="Processing">Processing</option>
                          <option value="Shipped">Shipped</option>
                          <option value="Delivered">Delivered</option>
                          <option value="Cancelled">Cancelled</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5} className="p-4 text-center">
                  <Typography variant="small" color="blue-gray">
                    No orders available.
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
}
