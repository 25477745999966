export const shippingPolicy = [
  {
    id: 1,
    title: "heading",
    description:
      "At DemonGears, we strive to provide a seamless and reliable shipping experience for our valued customers. ",
  },
  {
    id: 2,
    title: "1. Shipping Charges:",
    description:
      "Please note that shipping charges are calculated separately and added to your total order amount during the checkout process. We aim to provide competitive shipping rates to ensure your products reach you safely and efficiently.",
  },
  {
    id: 3,
    title: "2. Replacement for Damaged Products:",
    description:
      "We understand that accidents can happen during transit. If you receive a damaged product, please reach out to our customer support team within 48 hours of delivery.",
  },
  {
    id: 4,
    title: "Shipping Timeframe:",
    description:
      "Our standard shipping timeframe is between 5 to 7 business days, starting from the date your order is confirmed and payment is received. Please note that these estimates are subject to factors such as product availability and destination location. We appreciate your patience in waiting for your order to arrive.",
  },
  {
    id: 5,
    title: "Shipping Schedule:",
    description:
      "Please be aware that we do not process shipments on Sundays. If you place an order after 11:00 AM on any other business day, your order will be shipped on the following business day. We strive to ensure timely processing and dispatch of all orders.",
  },
  {
    id: 6,
    title: "Order Tracking:",
    description:
      "Once your order has been shipped, you will receive a confirmation email with a tracking number. You can use this tracking number to monitor the progress of your shipment and estimate the expected delivery date.",
  },
  {
    id: 7,
    title: "Query",
    description:
      "If you have any further questions or concerns regarding our shipping policy, please do not hesitate to contact our customer support team. Email: ————-are here to assist you throughout the shipping process and ensure your satisfaction with your DemonGears products.",
  },
  {
    id: 8,
    title: "Thank You",
    description:
      "Thank you for choosing DemonGears. We appreciate your business and look forward to serving you with exceptional products and services.",
  },
];