import {
  Carousel,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import Image1 from "../../assets/Home/belt-1.png";
import Image2 from "../../assets/Home/belt-2.png";
import Image3 from "../../assets/Home/belt-3.png";
import { Link } from "react-router-dom";

export function CarouselWithContent() {
  return (
    <Carousel
      className="rounded-xl"
      navigation={({ setActiveIndex, activeIndex, length }) => (
        <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
          {new Array(length).fill("").map((_, i) => (
            <span
              key={i}
              className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
              }`}
              onClick={() => setActiveIndex(i)}
            />
          ))}
        </div>
      )}
      prevArrow={({ handlePrev }) => (
        <IconButton
          variant="text"
          color="white"
          size="lg"
          onClick={handlePrev}
          className="!absolute top-2/4 left-4 -translate-y-2/4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
            />
          </svg>
        </IconButton>
      )}
      nextArrow={({ handleNext }) => (
        <IconButton
          variant="text"
          color="white"
          size="lg"
          onClick={handleNext}
          className="!absolute top-2/4 !right-4 -translate-y-2/4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </IconButton>
      )}
      autoplay={true}
      autoplayDelay={5000}
      transition={{ duration: 1, type: "tween" }}
      loop={true}
    >
      <div className="relative h-full w-full">
        <img
          src={Image1}
          alt="image 1"
          className="h-[90vh] w-full object-cover"
        />
        <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
          <div className="w-4/5 text-center md:w-2/4">
            {/* Heading */}
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-2xl md:text-4xl lg:text-5xl"
            >
              Elevate Your Performance with DemonGears
            </Typography>
            {/* Conditional Description for Different Screen Sizes */}
            <Typography
              variant="lead"
              color="white"
              className="mb-12 opacity-80 hidden md:block"
            >
              {/* Full Description for larger screens */}
              At DemonGears, we are passionate about empowering athletes to push
              their boundaries and reach new heights. As a young and driven
              brand, we bring our collective athletic experience to the
              forefront, creating products that serve one purpose: to help you
              perform at your best.
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-12 opacity-80 md:hidden"
            >
              {/* Short Description for mobile screens */}
              We empower athletes to push their boundaries with premium gear to
              fuel your journey.
            </Typography>
            {/* Buttons */}
            <div className="flex justify-center gap-2">
              <Link to="/products">
                <Button size="lg" color="white">
                  Explore
                </Button>
              </Link>
              <Link to="/about-us">
                <Button size="lg" color="white" variant="text">
                  Know more
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="relative h-full w-full">
        <img
          src={Image2}
          alt="image 2"
          className="h-[90vh] w-full object-cover"
        />
        <div className="absolute inset-0 grid h-full w-full items-center bg-black/75">
          <div className="w-3/4 pl-8 md:w-2/4 md:pl-20 lg:pl-32">
            {/* Heading */}
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-2xl md:text-4xl lg:text-5xl"
            >
              Deadlift Straps: Power Meets Comfort
            </Typography>
            {/* Conditional Description for Different Screen Sizes */}
            <Typography
              variant="lead"
              color="white"
              className="mb-12 opacity-80 hidden md:block"
            >
              {/* Full Description for larger screens */}
              Our Deadlift Straps are engineered for athletes who demand both
              toughness and comfort during their heaviest lifts. Crafted from
              high-density fabric with a unique soft cushioning, these straps
              ensure that your wrists are protected while maintaining a
              vice-like grip on the bar. Whether you're deadlifting, shrugging,
              or rowing, these straps provide you with the demon-like power to
              take control of the barbell, especially when lifting with
              aggressive knurling.
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-12 opacity-80 md:hidden"
            >
              {/* Short Description for mobile screens */}
              Deadlift with confidence—toughness and comfort, all in one.
            </Typography>
            {/* Buttons */}
            <div className="flex gap-2">
              <Link to="/products">
                <Button size="lg" color="white">
                  Explore
                </Button>
              </Link>
              <Link to="/about-us">
                <Button size="lg" color="white" variant="text">
                  Know more
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="relative h-full w-full">
        <img
          src={Image3}
          alt="image 3"
          className="h-[90vh] w-full object-cover"
        />
        <div className="absolute inset-0 grid h-full w-full items-end bg-black/75">
          <div className="w-3/4 pl-8 pb-8 md:w-2/4 md:pl-20 md:pb-20 lg:pl-32 lg:pb-32">
            {/* Heading */}
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-2xl md:text-4xl lg:text-5xl"
            >
              Designed for Strength and Durability
            </Typography>
            {/* Conditional Description for Different Screen Sizes */}
            <Typography
              variant="lead"
              color="white"
              className="mb-12 opacity-80 hidden md:block"
            >
              {/* Full Description for larger screens */}
              With a length of 21 inches and a width of 1.25 inches, our
              Deadlift Straps are perfect for securely wrapping around both your
              wrist and the bar, maximizing your grip and minimizing discomfort.
              The reinforced stitching prevents wear and tear, making them ideal
              for repeated use. Suitable for deadlifts, pull-ups, and other
              heavy-duty movements, these straps are your go-to solution for
              enhancing grip strength while protecting your wrists from strain.
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-12 opacity-80 md:hidden"
            >
              {/* Short Description for mobile screens */}
              Deadlift Straps—strength, durability, and ultimate grip support.
            </Typography>
            {/* Buttons */}
            <div className="flex gap-2">
              <Link to="/products">
                <Button size="lg" color="white">
                  Explore
                </Button>
              </Link>
              <Link to="/about-us">
                <Button size="lg" color="white" variant="text">
                  Know more
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
}
