import React from "react";
import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineHeader,
  TimelineIcon,
  TimelineBody,
  Typography,
} from "@material-tailwind/react";
import Image2 from "../assets/Home/IMG_4876.png";
import Image1 from "../assets/Home/IMG_4874.png";

const AboutUs = () => {
  return (
    <div className="w-11/12 mx-auto">
      <div className="mt-28 w-full flex items-center justify-center gap-3 flex-col  ">
        <h1 className="font-semibold text-xl md:text-3xl">
          Welcome To DemonGears
        </h1>
        <p className="text-gradient font-bold text-2xl md:text-4xl text-center">
          Unleash Your Power, Elevate Your Performance - DemonGears Has Your
          Back!
        </p>
        <p className=" w-[90%] md:w-[70%] md:text-lg text-sm text-center text-richblack-500">
          Welcome to DemonGears, where passion meets performance in the health
          and wellness industry. Our mission is simple – to elevate your
          training experience by providing premium gym gear and accessories that
          empower you to perform at your best.
        </p>
      </div>

      <div className="flex flex-col md:flex-row gap-5 mt-10">
        <div className="w-full md:w-1/2 ">
          <img
            className="h-[50vh] rounded-lg object-cover object-center mx-auto"
            src={Image1}
            alt="nature image"
          />
        </div>
        <div className="w-full md:w-1/2 flex flex-col justify-center items-center gap-3 bg-red-50 rounded-xl p-5">
          <h2 className="text-3xl text-gradient font-semibold">Our Mission</h2>
          <p className="text-center w-[90%]">
            At DemonGears, we understand that your athletic journey is more than
            just a workout – it’s a lifestyle. That’s why we are committed to
            offering only the highest quality products, meticulously designed to
            enhance both performance and comfort. Whether you’re in need of
            durable lifting belts, high-performance straps, or any other gym
            accessories, we have you covered with products engineered to
            withstand the toughest of workouts.
          </p>
        </div>
      </div>

      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1  gap-5 mt-10">
        <div className="bg-red-100 p-5 rounded-xl text-center">
          <h3 className="text-gradient font-bold text-xl mb-2">
            Premium Performance Gear
          </h3>
          <p className="">
            At DemonGears, we specialize in providing top-of-the-line gym gear
            designed for athletes who demand nothing less than the best. Our
            products, including durable lifting belts and high-performance
            straps, are engineered with precision and crafted from premium
            materials to ensure maximum support, comfort, and durability. When
            you choose DemonGears, you choose performance-enhancing gear built
            to last through the toughest workouts.
          </p>
        </div>

        <div className="bg-red-50 p-5 rounded-xl text-center">
          <h3 className="text-gradient font-bold text-xl mb-2">
            Athlete-Centered Design
          </h3>
          <p className="">
            We know what it takes to succeed in the gym because we are athletes
            ourselves. Every product at DemonGears is meticulously designed with
            the athlete in mind. Whether you’re looking for superior grip, extra
            support, or just more comfort during your workout, we have the
            solution. Our mission is to help you push past your limits and
            achieve new personal bests with gear that meets the highest
            standards of performance.
          </p>
        </div>

        <div className="bg-red-100 p-5 rounded-xl text-center">
          <h3 className="text-gradient font-bold text-xl mb-2">
            Join the DemonGears Community
          </h3>
          <p className="">
            DemonGears is more than just a brand – it’s a community. By choosing
            our products, you become part of a network of driven athletes who
            share your passion for fitness and resilience. Stay connected with
            us, follow our journey, and join the movement that celebrates
            dedication and hard work. Together, we’ll help you reach new heights
            in your athletic journey.
          </p>
        </div>
      </div>

      <div className="flex flex-col mt-10">
        <h3 className="text-gradient font-bold text-2xl md:text-3xl text-center">
          Why Choose DemonGears?
        </h3>

        <div className=" w-full flex gap-5 justify-center my-10">
          <div className="w-1/2 lg:block hidden">
            <img
              className="h-full  rounded-lg object-cover object-center"
              src={Image2}
              alt="nature image"
            />
          </div>
          <div className="w-[32rem] lg:w-1/2">
            <Timeline>
              <TimelineItem>
                <TimelineConnector />
                <TimelineHeader className="h-3">
                  <TimelineIcon />
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="leading-none"
                  >
                    Athlete-Centered Design
                  </Typography>
                </TimelineHeader>
                <TimelineBody className="pb-8">
                  <Typography
                    variant="small"
                    color="gray"
                    className="font-normal text-gray-600"
                  >
                    Our products are created with athletes in mind, ensuring
                    they meet the demands of intense training. From ergonomic
                    lifting belts to performance-enhancing accessories, each
                    item is built to support your fitness journey.
                  </Typography>
                </TimelineBody>
              </TimelineItem>
              <TimelineItem>
                <TimelineConnector />
                <TimelineHeader className="h-3">
                  <TimelineIcon />
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="leading-none"
                  >
                    Commitment to Quality
                  </Typography>
                </TimelineHeader>
                <TimelineBody className="pb-8">
                  <Typography
                    variant="small"
                    color="gary"
                    className="font-normal text-gray-600"
                  >
                    We use only premium materials for durability and comfort.
                    Our gear is designed to withstand tough workouts while
                    offering reliable support, so you can focus on pushing your
                    limits without worrying about your equipment.
                  </Typography>
                </TimelineBody>
              </TimelineItem>
              <TimelineItem>
                <TimelineConnector />
                <TimelineHeader className="h-3">
                  <TimelineIcon />
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="leading-none"
                  >
                    Passion for Excellence
                  </Typography>
                </TimelineHeader>
                <TimelineBody className="pb-8">
                  <Typography
                    variant="small"
                    color="gary"
                    className="font-normal text-gray-600"
                  >
                    We strive to help you achieve your fitness goals by
                    delivering high-performance gear. With a focus on
                    functionality and innovation, we’re committed to enhancing
                    your training experience and empowering you to succeed.
                  </Typography>
                </TimelineBody>
              </TimelineItem>
              <TimelineItem>
                <TimelineHeader className="h-3">
                  <TimelineIcon />
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="leading-none"
                  >
                    Community-Oriented
                  </Typography>
                </TimelineHeader>
                <TimelineBody>
                  <Typography
                    variant="small"
                    color="gary"
                    className="font-normal text-gray-600"
                  >
                    When you choose DemonGears, you join a passionate community
                    of athletes. Together, we push boundaries and celebrate hard
                    work, perseverance, and the pursuit of greatness in fitness.
                  </Typography>
                </TimelineBody>
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
