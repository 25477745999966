export const NavbarLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "About us",
    path: "/about-us",
  },
  // {
  //   title: "New Arrivals",
  //   path: '/new-arrivals',
  // },
  {
    title: "Products",
    path: "/products",
  },
  {
    title: "FAQ",
    path: "/faq",
  },
  {
    title: "Contact us",
    path: "/contact-us",
  },
];
