import toast from "react-hot-toast";
import { paymentEndpoints } from "../apis";
import { apiConnector } from "../apiConnector";
import { resetCart } from "../../slices/cartSlice";
import rzpLogo from "../../assets/Home/rzp_logo.png";

const {
  CAPTURE_PAYMENT,
  VERIFY_PAYMENT,
  SEND_PAYMENT_EMAIL,
  GET_INVOICE_HTML,
} = paymentEndpoints;

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;

    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };

    document.body.appendChild(script);
  });
}

export async function buyProducts({
  token,
  orderData,
  userDetails,
  navigate,
  dispatch,
}) {
  const toastId = toast.loading("Loading...");
  try {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      toast.error("RazorPay SDK failed to load");
    }

    // initate the order
    const orderResponse = await apiConnector(
      "POST",
      CAPTURE_PAYMENT,
      { orderData },
      { Authorization: `Bearer ${token}` }
    );

    if (!orderResponse.data.success) {
      throw new Error(orderResponse.data.message);
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      currency: orderResponse.data.message.currency,
      amount: `${orderResponse.data.message.amount}`,
      order_id: orderResponse.data.message.id,
      name: "DemonGears",
      description: "Thank you for Purchasing from us.",
      image: rzpLogo,
      prefill: {
        name: `${userDetails.firstName || ""}`,
        email: `${userDetails.email || ""}`,
      },
      handler: function (response) {
        // send successful email
        sendPaymentSuccessEmail(
          response,
          orderResponse.data.message.amount,
          token
        );

        // verify payment
        verifyPayment({
          bodyData: {
            receiptId: orderResponse.data.message.receipt,
            ...response,
            orderData,
          },
          token,
          navigate,
          dispatch,
        });
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on("payment.failed", function (response) {
      toast.error(response.error.description);
      console.log(response.error.description);
    });
  } catch (error) {
    console.log("PAYMET API ERROR..... ", error);
    toast.error("Could not make Payment");
  }
  toast.dismiss(toastId);
}

async function sendPaymentSuccessEmail(response, amount, token) {
  try {
    await apiConnector(
      "POST",
      SEND_PAYMENT_EMAIL,
      {
        orderId: response.razorpay_order_id,
        paymentId: response.razorpay_payment_id,
        amount,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
  } catch (error) {
    console.log("PAYMENT SUCCESS EMAIL ERROR....", error);
  }
}

async function verifyPayment({ bodyData, token, navigate, dispatch }) {
  const toastId = toast.loading("Verfiying payment....");
  try {
    const response = await apiConnector("POST", VERIFY_PAYMENT, bodyData, {
      Authorization: `Bearer ${token}`,
    });
    if (!response?.data?.success) {
      throw new Error(response.data.message);
    }
    toast.success("Payment successful, your order will be shipped soon");
    navigate("/profile/orders");
    dispatch(resetCart());
  } catch (error) {
    console.log("PAYMENT VERIFY ERROR....", error);
    toast.error("Could not verify Payment");
  } finally {
    toast.dismiss(toastId);
  }
}

export async function getInvoiceHtml(orderId, token) {
  try {
    const response = await apiConnector(
      "POST",
      GET_INVOICE_HTML,
      { orderId },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    return response?.data;
  } catch (error) {
    console.log("GET INVOICE API ERROR..... ", error);
    toast.error("Could Generate Invoice");
  }
}
