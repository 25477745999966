import React from 'react'

const NewArrivals = () => {
  return (
    <div>
      NEW ARRIVALS
    </div>
  )
}

export default NewArrivals
