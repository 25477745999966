import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Textarea,
  Typography,
  Select,
} from "@material-tailwind/react";
import Upload from "../Profile/Upload"; // Assuming Upload component is for image upload
import {
  addProductDetails,
  getAllCategoryName,
} from "../../services/operations/Product"; // Your API function
import { useSelector } from "react-redux";
import { CiCircleRemove } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

export function AddProductForm() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [stock, setStock] = useState("");
  const { token } = useSelector((state) => state.auth);
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [colors, setColors] = useState([{ color: "", images: [] }]);
  const [features, setFeatures] = useState({
    weight: "",
    dimensions: "",
    material: "",
  });

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleAddColor = () => {
    setColors([...colors, { color: "", images: [] }]);
  };

  const handleRemoveColor = (index) => {
    const updatedColors = colors.filter((_, i) => i !== index);
    setColors(updatedColors);
  };

  const handleColorChange = (index, value) => {
    const updatedColors = colors.map((item, i) =>
      i === index ? { ...item, color: value } : item
    );
    setColors(updatedColors);
  };

  const handleImageUpload = (index, uploadedFiles) => {
    const updatedColors = colors.map((item, i) =>
      i === index ? { ...item, images: uploadedFiles } : item
    );
    setColors(updatedColors);
  };

  const handleFeaturesChange = (e) => {
    const { name, value } = e.target;
    setFeatures({
      ...features,
      [name]: value,
    });
  };

  const handleAddTag = (e) => {
    if (e.key === "Enter" && e.target.value) {
      setTags([...tags, e.target.value]);
      e.target.value = "";
    }
  };

  const handleRemoveTag = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const onSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const formData = new FormData();

    formData.append("name", event.target.name.value);
    formData.append("description", event.target.description.value);
    formData.append("price", event.target.price.value);
    formData.append("discountedPrice", event.target.discountedPrice.value);
    formData.append("stock", stock);
    formData.append("features", JSON.stringify(features));
    formData.append("category", category || newCategory);
    formData.append("tags", tags.join(","));

    colors.forEach((colorItem, index) => {
      if (colorItem.color) {
        formData.append(`colors[${index}][color]`, colorItem.color);
      }

      colorItem.images.forEach((image) => {
        formData.append(`colors[${index}][images]`, image);
      });
    });

    try {
      const result = await addProductDetails(formData, token);
      setLoading(false);
    } catch (error) {
      console.error("Failed to add product", error);
    } finally {
      navigate("/profile/allproducts");
    }
  };

  return (
    <form
      onSubmit={onSubmit}
      enctype="multipart/form-data"
      className="space-y-4 pb-6 sm:space-y-6"
      onKeyDown={handleKeyDown}
    >
      <div>
        <Typography variant="small" color="blue-gray" className="mb-2">
          Name
        </Typography>
        <Input
          name="name"
          placeholder="Product name"
          required
          className="w-full"
        />
      </div>

      <div>
        <Typography variant="small" color="blue-gray" className="mb-2">
          Description
        </Typography>
        <Textarea
          name="description"
          rows={5}
          placeholder="Write description"
          required
          className="w-full"
        />
      </div>

      <div>
        <Typography variant="small" color="blue-gray" className="mb-2">
          Original Price
        </Typography>
        <Input
          name="price"
          type="number"
          placeholder="e.g. 100.00"
          required
          className="w-full"
        />
      </div>

      <div>
        <Typography variant="small" color="blue-gray" className="mb-2">
          Discounted Price
        </Typography>
        <Input
          name="discountedPrice"
          type="number"
          placeholder="e.g. 80.00"
          required
          className="w-full"
        />
      </div>

      <div>
        <Typography variant="small" color="blue-gray" className="mb-2">
          Stock
        </Typography>
        <Input
          color="gray"
          size="lg"
          placeholder="Enter stock"
          value={stock}
          onChange={(e) => setStock(e.target.value)}
          type="number"
          className="w-full placeholder:opacity-100 focus:!border-t-gray-900"
          containerProps={{
            className: "!min-w-full",
          }}
          labelProps={{
            className: "hidden",
          }}
        />
      </div>

      <div>
        <Typography variant="small" color="blue-gray" className="mb-2">
          Category
        </Typography>
        <Select
          value={category}
          onChange={(e) => setCategory(e)}
          label="Select Category"
          className="w-full"
        >
          <option value="Clothing">Clothing</option>
          <option value="Fashion">Fashion</option>
          <option value="Watches">Watches</option>
          <option value="Gym Equipment">Gym Equipment</option>
        </Select>
        <Input
          color="gray"
          size="lg"
          placeholder="Type new category here"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          className="mt-2 w-full"
        />
      </div>

      <div>
        <Typography variant="small" color="blue-gray" className="mb-2">
          Product Features
        </Typography>
        <div className="space-y-2">
          <Input
            name="weight"
            placeholder="Weight (e.g., 1.5 kg)"
            value={features.weight}
            onChange={handleFeaturesChange}
            className="w-full"
          />
          <Input
            name="dimensions"
            placeholder="Dimensions (e.g., 10x5x3 cm)"
            value={features.dimensions}
            onChange={handleFeaturesChange}
            className="w-full"
          />
          <Input
            name="material"
            placeholder="Material (e.g., Cotton, Steel)"
            value={features.material}
            onChange={handleFeaturesChange}
            className="w-full"
          />
        </div>
      </div>

      <div>
        <Typography variant="small" color="blue-gray" className="mb-2">
          Product Colors and Images (Max 5 images per color)
        </Typography>
        {colors.map((colorItem, index) => (
          <div key={index} className="space-y-2">
            <div className="flex flex-wrap items-center gap-4">
              <Input
                value={colorItem.color}
                onChange={(e) => handleColorChange(index, e.target.value)}
                placeholder={`Color ${index + 1}`}
                required
                className="w-full sm:w-1/2"
              />
              <Button
                color="red"
                onClick={() => handleRemoveColor(index)}
                className="mt-2 sm:mt-0"
              >
                Remove Color
              </Button>
            </div>
            <Upload
              name={`colorImages-${index}`}
              label={`Upload images for ${
                colorItem.color || `Color ${index + 1}`
              }`}
              maxFiles={5}
              onUpload={(uploadedImages) =>
                handleImageUpload(index, uploadedImages)
              }
              className="w-full"
            />
          </div>
        ))}
        <Button onClick={handleAddColor} className="mt-2">
          Add More Color
        </Button>
      </div>

      <div>
        <Typography variant="small" color="blue-gray" className="mb-2">
          Tags
        </Typography>
        <div className="space-y-2">
          <Input
            onKeyDown={handleAddTag}
            placeholder="Enter a tag and press Enter"
            className="w-full"
          />
          <div className="flex flex-wrap gap-2">
            {tags.map((tag, index) => (
              <span
                key={index}
                className="flex items-center space-x-1 bg-blue-100 p-2 rounded-lg"
              >
                <Typography variant="small" className="text-blue-gray-900">
                  {tag}
                </Typography>
                <CiCircleRemove
                  className="text-red-400 cursor-pointer"
                  onClick={() => handleRemoveTag(index)}
                />
              </span>
            ))}
          </div>
        </div>
      </div>

      <Button type="submit" disabled={loading}>
        {loading ? "Saving..." : "Save Product"}
      </Button>
    </form>
  );
}
