import ChangeProfilePicture from "./ChangeProfilePicture";
import DeleteAccount from "./DeleteAccount";
import ProfileInfo from "./ProfileInfo";
import UpdatePassword from "./UpdatePassword";

export default function EditProfile() {
  return (
    <>
      <h1 className="mb-14 text-3xl mt-6 font-bold text-richblack-800">
        Edit Profile
      </h1>
      {/* Change Profile Picture */}
      <ChangeProfilePicture />
      {/* Profile */}
      <ProfileInfo />
      {/* Password */}
      <UpdatePassword />
      {/* Delete Account */}
      {/* <DeleteAccount /> */}
    </>
  );
}
