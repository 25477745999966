import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { getInvoiceHtml } from "../services/operations/Payment";
import toast from "react-hot-toast";

export const generateInvoice = async (orderId, token) => {
  try {
    const response = await getInvoiceHtml(orderId, token);
    const html = response.data;

    // Create a temporary element to hold the HTML content
    const tempElement = document.createElement("div");
    tempElement.style.width = "800px"; // Ensure consistent width for rendering
    tempElement.innerHTML = html;
    document.body.appendChild(tempElement);

    // Use html2canvas to capture the full content
    const canvas = await html2canvas(tempElement, {
      scale: 2, // Higher scale for better resolution
      scrollX: 0,
      scrollY: 0,
      windowWidth: tempElement.scrollWidth,
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "pt", "a4");

    // Calculate dimensions to fit content into multiple pages
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
    const aspectRatio = imgWidth / imgHeight;

    let positionY = 0;
    let remainingHeight = imgHeight;

    // Add images to the PDF page by page
    while (remainingHeight > 0) {
      const currentHeight = Math.min(imgHeight, imgWidth / aspectRatio);
      pdf.addImage(
        imgData,
        "PNG",
        0,
        positionY,
        pdfWidth,
        pdfWidth / aspectRatio
      );
      remainingHeight -= pdfHeight;
      positionY -= pdfHeight;

      if (remainingHeight > 0) {
        pdf.addPage();
      }
    }

    pdf.save(`Invoice_${orderId}.pdf`);

    // Cleanup the temporary element
    document.body.removeChild(tempElement);
  } catch (error) {
    toast.error("Cannot generate the invoice. Please try again later");
  }
};
