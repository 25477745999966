import React, { useState } from "react";
import {
  Card,
  Typography,
  CardBody,
  Button,
  CardFooter,
} from "@material-tailwind/react";
import { deleteAddressDetails } from "../../services/operations/profileAPI";
import ConfirmationModal from "../common/ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { DrawerPlacement } from "../UI/DrawerPlacement";

const AddressCard = ({
  address,
  index,
  setUser,
  user,
  setAddressData,
  setIsEdit,
}) => {
  const { token } = useSelector((state) => state.auth);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Manage Drawer state
  const [drawerData, setDrawerData] = useState(null); // Data to pass to Drawer
  const dispatch = useDispatch();

  const deleteHandler = async (addressId) => {
    try {
      const result = await deleteAddressDetails(token, addressId);
      setUser(result?.addresses);
      setConfirmationModal(null);
    } catch (error) {
      console.error("Failed to delete address", error);
      alert("Failed to delete address. Please try again.");
    }
  };

  const editHandler = async (addressId) => {
    try {
      // Find the matched address by comparing the addressId
      const matchedAddress = user.addresses.find(
        (address) => address._id === addressId
      );

      if (matchedAddress) {
        // Open Drawer with matched address data
        // setDrawerData({
        //   isEdit: true,
        //   addressData: matchedAddress,
        // });
        // setIsDrawerOpen(true);
        setIsEdit(true);
        setAddressData(matchedAddress);
      } else {
        console.log("No address found with the given addressId.");
      }
    } catch (error) {
      console.error("Error in editHandler:", error);
    }
  };

  return (
    <>
      <Card
        color="grey"
        variant="filled"
        className="mt-6 w-full max-w-xs mx-auto p-4"
        key={address._id}
      >
        <CardBody>
          <Typography variant="h5" color="blue-gray" className="mb-2">
            {address?.firstName} {address?.lastName}
          </Typography>
          <Typography>{address.address1}</Typography>
          <Typography>{address.address2}</Typography>
          <Typography>{address.city}</Typography>
          <Typography>{address.country}</Typography>
          <Typography>{address.zipCode}</Typography>
          <Typography>Contact: {address.contactNumber}</Typography>
        </CardBody>
        <CardFooter divider className="flex justify-between py-3">
          <Button
            // color="blue"
            onClick={() => editHandler(address._id)}
            className="w-1/2 mr-1 text-gradient"
          >
            Edit
          </Button>
          <Button
            color="red"
            variant="outlined"
            onClick={() =>
              setConfirmationModal({
                text1: "Delete this Address?",
                text2:
                  "All the details related to this Address will be deleted.",
                btn1Text: "Delete",
                btn2Text: "Cancel",
                btn1Handler: () => deleteHandler(address._id),
                btn2Handler: () => setConfirmationModal(null),
              })
            }
            className="w-1/2 ml-1"
          >
            Delete
          </Button>
        </CardFooter>
        {confirmationModal && (
          <ConfirmationModal modalData={confirmationModal} />
        )}
      </Card>

      {/* Drawer Component */}
      {/* {isDrawerOpen && (
        <DrawerPlacement
          setUser={setUser}
          isEdit={drawerData.isEdit}
          addressData={drawerData.addressData}
          onClose={() => setIsDrawerOpen(false)} // Pass a close handler
        />
      )} */}
    </>
  );
};

export default AddressCard;
