import React, { useState } from "react";
import styles from "../styles/styles";
import { faqData } from "../data/faqData";
import { AccordionCustomIcon } from "../components/UI/Accordion";

const Faq = () => {
  const [activeTab, setActiveTab] = useState(0);

  const toggleTab = (tab) => {
    setActiveTab(activeTab === tab ? 0 : tab);
  };

  return (
    <div className={`${styles.section} px-4 sm:px-6`}>
      <div className="lg:w-10/12 md:w-full mx-auto">
        <h2 className="text-3xl sm:text-4xl font-bold text-gradient mb-6 text-center">
          Frequently Asked Questions
        </h2>

        <div className="space-y-6 sm:space-y-8">
          {/* Adjust padding for better spacing on small screens */}
          <AccordionCustomIcon data={faqData} />
        </div>
      </div>
    </div>
  );
};

export default Faq;
