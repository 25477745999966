import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllUserData } from "../../services/operations/profileAPI";
import { getAllOrdersForUser } from "../../services/operations/order";
import OrderCard from "../order/OrderCard ";

const Orders = () => {
  const { token } = useSelector((state) => state.auth);
  const [user, setUser] = useState([]);
  const [userId, setUserId] = useState("");
  const [allOrders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (token) {
          const response = await getAllUserData(token, dispatch);
          setUser(response.userDetails);
          setUserId(response.userDetails._id);
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };

    const fetchOrdersForUser = async () => {
      try {
        if (userId && token) {
          const response = await getAllOrdersForUser(userId, token);
          // Sort orders by date (descending)
          const sortedOrders = response.orders.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setAllOrders(sortedOrders);
        }
      } catch (error) {
        console.error("Error fetching user orders", error);
      }
    };

    // Combined fetching function
    const fetchData = async () => {
      setLoading(true); // Ensure loading state is true before fetching starts
      await fetchUserData();
      await fetchOrdersForUser();
      setLoading(false); // Set loading to false after both functions complete
    };

    fetchData();
  }, [token, userId, dispatch]);

  return (
    <div className="flex flex-col items-center p-4 w-full sm:w-11/12 lg:w-3/4 mx-auto">
      <h2 className="text-2xl font-bold mb-4">Orders</h2>
      {loading ? (
        <div className="flex justify-center items-center">
          <div className="spinner"></div>
        </div>
      ) : allOrders.length > 0 ? (
        allOrders.map((order) => <OrderCard key={order._id} order={order} />)
      ) : (
        <div className="text-center">
          <p className="text-xl font-semibold mb-4">
            You haven't placed any orders yet.
          </p>
          <button
            onClick={() => navigate("/products")}
            className="px-4 py-2 bg-red-500 text-white font-semibold rounded-lg hover:bg-red-600 transition duration-200"
          >
            Start Shopping
          </button>
        </div>
      )}
    </div>
  );
};

export default Orders;
