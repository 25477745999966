import React from "react";
import { returnPolicyContent } from "../data/returnPolicy";

const Return = () => {
  return (
    <div className="w-full p-4 sm:p-2 md:p-2 mt-16">
      <div className="w-full lg:w-6/12 mx-auto">
        {/* Title */}
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-richblack-800 mb-6 sm:mb-8 text-center">
          {returnPolicyContent.title}
        </h2>

        {/* Introduction */}
        <p className="text-left text-xs sm:text-sm md:text-base mt-2 sm:mt-4 mb-4 sm:mb-8 text-richblack-600 leading-relaxed">
          {returnPolicyContent.introduction}
        </p>

        {/* Cancellation Policy */}
        <div className="mb-4 sm:mb-6 text-left">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-richblack-800 mb-2 sm:mb-4">
            {returnPolicyContent.cancellation.title}
          </h3>
          <p className="mt-2 text-richblack-700 font-medium text-xs sm:text-sm md:text-lg leading-relaxed">
            {returnPolicyContent.cancellation.description}
          </p>
        </div>

        {/* Conditions for Returns */}
        <div className="mb-4 sm:mb-6 text-left">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-richblack-800 mb-2 sm:mb-4">
            {returnPolicyContent.returnConditions.title}
          </h3>
          <p className="mt-2 text-richblack-700 font-medium text-xs sm:text-sm md:text-lg leading-relaxed">
            {returnPolicyContent.returnConditions.description}
          </p>
        </div>

        {/* Refund Policy */}
        <div className="mb-4 sm:mb-6 text-left">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-richblack-800 mb-2 sm:mb-4">
            {returnPolicyContent.refund.title}
          </h3>
          <p className="mt-2 text-richblack-700 font-medium text-xs sm:text-sm md:text-lg leading-relaxed">
            {returnPolicyContent.refund.description}
          </p>
        </div>

        {/* Exchange Policy */}
        <div className="mb-4 sm:mb-6 text-left">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-richblack-800 mb-2 sm:mb-4">
            {returnPolicyContent.exchange.title}
          </h3>
          <p className="mt-2 text-richblack-700 font-medium text-xs sm:text-sm md:text-lg leading-relaxed">
            {returnPolicyContent.exchange.description}
          </p>
        </div>

        {/* Return Process */}
        <div className="mb-4 sm:mb-6 text-left">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-richblack-800 mb-2 sm:mb-4">
            {returnPolicyContent.process.title}
          </h3>
          <p className="mt-2 whitespace-pre-line text-richblack-700 font-medium text-xs sm:text-sm md:text-lg leading-relaxed">
            {returnPolicyContent.process.description}
          </p>
        </div>

        {/* Questions */}
        <div className="mb-4 sm:mb-6 text-left">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-richblack-800 mb-2 sm:mb-4">
            {returnPolicyContent.questions.title}
          </h3>
          <p
            dangerouslySetInnerHTML={{
              __html: returnPolicyContent.questions.description,
            }}
            className="mt-2 text-richblack-700 font-medium text-xs sm:text-sm md:text-lg leading-relaxed"
          />
        </div>
      </div>
    </div>
  );
};

export default Return;
