import { setUser } from "../../slices/profileSlice";
import { apiConnector } from "../apiConnector";
import { subscribeEndpoints } from "../apis";
import toast from "react-hot-toast";

const { CREATE_SUBSCRIBER_API } = subscribeEndpoints;

export const addNewSubscriber = async (email) => {
  const toastId = toast.loading("Loading...");
  let result = null;
  try {
    const response = await apiConnector("POST", CREATE_SUBSCRIBER_API, {
      email,
    });
    if (response.data.message) {
      toast.success(response.data.message);
    }
    result = response?.data;
  } catch (error) {
    toast.error(error.message);
  }

  toast.dismiss(toastId);
  return result;
};
