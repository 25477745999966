import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import useNavigate
import { fetchOrderDetailsById } from "../../services/operations/order";
import { useSelector } from "react-redux";
import { Button, Spinner } from "@material-tailwind/react";
import { FaArrowLeft } from "react-icons/fa";
import IconBtn from "../common/IconBtn";
import { generateInvoice } from "../../utils/billGenerator";

const OrderDetails = () => {
  const { token } = useSelector((state) => state.auth);
  const { orderId } = useParams();
  const navigate = useNavigate(); // Initialize navigate hook
  const [loading, setLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState(null);
  const [shippingAddress, setShippingAddress] = useState(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      setLoading(true);
      const response = await fetchOrderDetailsById(token, orderId);
      setOrderDetails(response.order);

      // Match shipping address
      const matchedAddress = response.order.user.addresses.find(
        (address) =>
          address.address1 === response.order.shippingAddress.address1
      );
      setShippingAddress(matchedAddress);

      setLoading(false);
    };
    fetchOrderDetails();
  }, [orderId, token]);

  if (loading) {
    return (
      <div className="w-full h-[70vh] flex items-center justify-center">
        <Spinner color="red" className="h-10 w-10" />
      </div>
    );
  }

  if (!orderDetails) {
    return (
      <div className="text-center text-red-500">No order details found.</div>
    );
  }

  const {
    user,
    orderItems,
    totalAmount,
    paymentInfo,
    statusHistory,
    orderStatus,
  } = orderDetails;
  console.log("orderStatus", orderStatus);
  return (
    <div className="p-4 md:p-6">
      <header className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-6">
        <button
          onClick={() => navigate("/profile/recent-orders")} // Navigate back on click
          className="flex items-center gap-2 text-sm font-semibold text-gray-700 hover:text-gray-900"
        >
          <FaArrowLeft className="h-5 w-5" />
          Back to Orders
        </button>
        <h1 className="text-xl md:text-2xl font-bold">Order #{orderId}</h1>
        <span
          className={`px-3 py-1 md:px-4 md:py-2 rounded-md font-semibold text-white ${
            orderStatus === "Processing"
              ? "bg-yellow-600"
              : orderStatus === "Shipped"
              ? "bg-orange-600"
              : orderStatus === "Delivered"
              ? "bg-green-600"
              : orderStatus === "Cancelled"
              ? "bg-red-600"
              : "bg-gray-500"
          }`}
        >
          {orderStatus}
        </span>
      </header>

      {/* Main content */}
      <div className="grid grid-cols-1 gap-4 md:gap-6 md:grid-cols-2">
        {/* Customer Info */}
        <div className="border rounded-lg p-3 md:p-4 shadow-sm">
          <h2 className="text-base md:text-lg font-semibold mb-2">
            Customer Info
          </h2>
          <p className="text-sm mb-1">
            <strong>Name:</strong> {user?.firstName} {user?.lastName}
          </p>
          <p className="text-sm">
            <strong>Email:</strong> {user?.email}
          </p>
        </div>

        {/* Shipping Address */}
        <div className="border rounded-lg p-3 md:p-4 shadow-sm">
          <h2 className="text-base md:text-lg font-semibold mb-2">
            Shipping Address
          </h2>
          {shippingAddress ? (
            <>
              <p className="text-sm mb-1">
                <strong>Address Line 1:</strong> {shippingAddress.address1}
              </p>
              <p className="text-sm mb-1">
                <strong>Address Line 2:</strong> {shippingAddress.address2}
              </p>
              <p className="text-sm mb-1">
                <strong>City:</strong> {shippingAddress.city}
              </p>
              <p className="text-sm mb-1">
                <strong>Country:</strong> {shippingAddress.country}
              </p>
              <p className="text-sm mb-1">
                <strong>ZIP Code:</strong> {shippingAddress.zipCode}
              </p>
              <p className="text-sm">
                <strong>Contact Number:</strong> {shippingAddress.contactNumber}
              </p>
            </>
          ) : (
            <p className="text-sm text-red-500">Shipping address not found.</p>
          )}
        </div>

        {/* Payment Info */}
        <div className="border rounded-lg p-3 md:p-4 shadow-sm">
          <h2 className="text-base md:text-lg font-semibold mb-2">
            Payment Info
          </h2>
          <p className="text-sm mb-1">
            <strong>Status:</strong> {paymentInfo?.paymentStatus}
          </p>
          <p className="text-sm mb-1">
            <strong>Method:</strong> {paymentInfo?.method}
          </p>
          <p className="text-sm">
            <strong>Total Amount:</strong> ₹{totalAmount}
          </p>
        </div>

        {/* Download bill */}
        <div className="flex flex-col gap-5">
          <p className="text-lg ">Download your invoice</p>
          <IconBtn
            onclick={() => generateInvoice(orderId, token)}
            customClasses={"flex item-center justify-center w-1/2"}
          >
            Download
          </IconBtn>
        </div>

        {/* Order Items */}
        <div className="border rounded-lg p-3 md:p-4 shadow-sm col-span-1 md:col-span-2 overflow-x-auto">
          <h2 className="text-base md:text-lg font-semibold mb-2">
            Order Items
          </h2>
          <table className="w-full border-collapse border border-gray-200 text-sm">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 p-2 text-left">Item</th>
                <th className="border border-gray-300 p-2 text-left">
                  Quantity
                </th>
                <th className="border border-gray-300 p-2 text-left">Price</th>
              </tr>
            </thead>
            <tbody>
              {orderItems?.map((item) => (
                <tr key={item._id}>
                  <td className="border border-gray-300 p-2">
                    {item?.product?.name}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {item?.quantity}
                  </td>
                  <td className="border border-gray-300 p-2">₹{item?.price}</td>
                </tr>
              ))}
              <tr>
                <td className="border border-gray-300 p-2 font-semibold">
                  Delivery Charge
                </td>
                <td className="border border-gray-300 p-2 text-center">-</td>
                <td className="border border-gray-300 p-2">₹49</td>
              </tr>
              <tr className="bg-gray-100">
                <td className="border border-gray-300 p-2 font-bold">
                  Total Amount
                </td>
                <td className="border border-gray-300 p-2 text-center">-</td>
                <td className="border border-gray-300 p-2 font-bold">
                  ₹{totalAmount}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Status History */}
        <div className="border rounded-lg p-3 md:p-4 shadow-sm">
          <h2 className="text-base md:text-lg font-semibold mb-2">
            Status History
          </h2>
          <ul className="space-y-1">
            {statusHistory
              ?.map((status) => status?.status)
              .filter((value, index, self) => self.indexOf(value) === index)
              .map((uniqueStatus, index) => {
                const firstOccurrence = statusHistory.find(
                  (status) => status.status === uniqueStatus
                );

                return (
                  <li key={index} className="text-sm">
                    <strong>{uniqueStatus}:</strong>{" "}
                    {new Date(firstOccurrence?.timestamp).toLocaleString()}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
