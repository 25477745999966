import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";

const initialState = {
  totalItems: localStorage.getItem("totalItems")
    ? JSON.parse(localStorage.getItem("totalItems"))
    : 0,
  cart: localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    setTotalItems(state, value) {
      state.cart = value.payload;
    },
    // rest cart
    resetCart: (state) => {
      state.cart = [];
      state.totalItems = 0;
      // update to local storage
      localStorage.removeItem("cart");
      localStorage.removeItem("totalItems");
    },
    // add to cart
    addToCart: (state, action) => {
      const product = action.payload;
      const productId = product.productId;
      const color = product.color;
      const index = state.cart.findIndex(
        (item) => item.productId === productId
      );

      if (index >= 0) {
        // if the product is already in the cart,increase the quantity
        state.cart[index].quantity += 1;
      } else {
        // if the product is not in the cart, add it to the cart
        state.cart.push({ productId, quantity: 1, color });
        toast.success("Product added to cart");
      }

      // update the total quantity
      state.totalItems++;

      // update to localstorage
      localStorage.setItem("cart", JSON.stringify(state.cart));
      localStorage.setItem("totalItems", JSON.stringify(state.totalItems));
    },
    // remover from cart
    removeFromCart: (state, action) => {
      const productId = action.payload;
      const index = state.cart.findIndex(
        (item) => item.productId === productId
      );

      if (index >= 0) {
        // if the product is found in the cart, remove it
        state.totalItems--;
        if (state.cart[index].quantity > 1) {
          state.cart[index].quantity -= 1;
        } else {
          state.cart.splice(index, 1);
          toast.success("Product removed from cart");
        }

        // update to localstorage
        localStorage.setItem("cart", JSON.stringify(state.cart));
        localStorage.setItem("totalItems", JSON.stringify(state.totalItems));
      }
    },

    removeProductFromCart: (state, action) => {
      const productId = action.payload;

      const index = state.cart.findIndex(
        (item) => item.productId === productId
      );
      if (index >= 0) {
        // if the product is found in the cart, remove it
        state.totalItems = state.totalItems - state.cart[index].quantity;
        state.cart.splice(index, 1);

        toast.success("Product removed from cart");

        // update to localstorage
        localStorage.setItem("cart", JSON.stringify(state.cart));
        localStorage.setItem("totalItems", JSON.stringify(state.totalItems));
      }
    },
  },
});

export const {
  setTotalItems,
  resetCart,
  removeFromCart,
  addToCart,
  removeProductFromCart,
} = cartSlice.actions;
export default cartSlice.reducer;
