import React from "react";
import { termsAndCondition } from "../data/termsAndCondition";

const TermsAndConditions = () => {
  return (
    <div className="w-full p-4 sm:p-2 md:p-2 mt-16">
      <div className="w-full lg:w-6/12 mx-auto">
        {/* Title */}
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-richblack-800 mb-6 sm:mb-8 text-center">
          Terms & Conditions for DemonGears
        </h1>

        {/* Overview */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-richblack-800 mb-4">
            Overview
          </h2>
          <p className="text-richblack-700 leading-relaxed">
            This website is operated by DemonGears. Throughout the site, the
            terms “we,” “us,” and “our” refer to DemonGears. DemonGears offers
            this website, including all information, tools, and services
            available from this site to you, the user, conditioned upon your
            acceptance of all terms, conditions, policies, and notices stated
            here.
          </p>
          <p className="text-richblack-700 leading-relaxed mt-4">
            By visiting our site and/or purchasing something from us, you engage
            in our “Service” and agree to be bound by the following terms and
            conditions (“Terms of Service,” “Terms”), including those additional
            terms and conditions and policies referenced herein and/or available
            by hyperlink. These Terms of Service apply to all users of the site,
            including without limitation users who are browsers, vendors,
            customers, merchants, and/or contributors of content.
          </p>
          <p className="text-richblack-700 leading-relaxed mt-4">
            Please read these Terms of Service carefully before accessing or
            using our website. By accessing or using any part of the site, you
            agree to be bound by these Terms of Service. If you do not agree to
            all the terms and conditions of this agreement, then you may not
            access the website or use any services. If these Terms of Service
            are considered an offer, acceptance is expressly limited to these
            Terms of Service.
          </p>
        </section>

        {/* Sections */}
        {termsAndCondition.map((section, index) => (
          <section key={index} className="mb-6">
            <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-richblack-800 mb-2 sm:mb-4">
              {section.title}
            </h3>
            <p className="mt-2 text-richblack-700 font-medium text-xs sm:text-sm md:text-lg leading-relaxed">
              {section.content}
            </p>
          </section>
        ))}
      </div>
    </div>
  );
};

export default TermsAndConditions;
