import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="w-full p-4 sm:p-2 md:p-2 mt-16">
      <div className="w-full lg:w-6/12 mx-auto">
        {/* Title */}
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-richblack-800 mb-6 sm:mb-8 text-center">
          Privacy Policy for DemonGears
        </h1>

        {/* Content */}
        <section className="mb-6">
          {/* <p className="text-richblack-700 leading-relaxed">
            DemonGears Private Limited operates the www.demongears.com website,
            which provides the SERVICE.
          </p> */}
          <p className="text-richblack-700 leading-relaxed mt-4">
            This Privacy Policy is intended to inform website visitors about our
            policies regarding the collection, use, and disclosure of Personal
            Information for anyone who decides to use our Service, the
            DemonGears website.
          </p>
          <p className="text-richblack-700 leading-relaxed mt-4">
            By using our Service, you agree to the collection and use of
            information in accordance with this policy. The Personal Information
            that we collect is used to provide and improve the Service. We do
            not use or share your information with anyone except as described in
            this Privacy Policy.
          </p>
          <p className="text-richblack-700 leading-relaxed mt-4">
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, accessible at www.demongears.com, unless
            otherwise defined.
          </p>
        </section>

        {/* Information Collection and Use */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-richblack-800 mb-4">
            Information Collection and Use
          </h2>
          <p className="text-richblack-700 leading-relaxed">
            For a better experience while using our Service, we may require you
            to provide certain personally identifiable information, including
            but not limited to:
          </p>
          <ul className="list-disc pl-6 mt-2 text-richblack-700">
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Postal address</li>
          </ul>
          <p className="text-richblack-700 leading-relaxed mt-4">
            This information is used to contact or identify you and to process
            your orders effectively.
          </p>
        </section>

        {/* Log Data */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-richblack-800 mb-4">
            Log Data
          </h2>
          <p className="text-richblack-700 leading-relaxed">
            We want to inform you that whenever you visit our Service, we
            collect information that your browser sends to us, known as Log
            Data. This Log Data may include:
          </p>
          <ul className="list-disc pl-6 mt-2 text-richblack-700">
            <li>Your computer’s Internet Protocol (IP) address</li>
            <li>Browser type and version</li>
            <li>Pages of our Service that you visit</li>
            <li>Time and date of your visit</li>
            <li>Time spent on those pages</li>
            <li>Other diagnostic data</li>
          </ul>
          <p className="text-richblack-700 leading-relaxed mt-4">
            This data is used to analyze and improve the performance of our
            website.
          </p>
        </section>

        {/* Cookies */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-richblack-800 mb-4">
            Cookies
          </h2>
          <p className="text-richblack-700 leading-relaxed">
            Cookies are small data files stored on your device. Our website uses
            cookies to enhance your experience and improve our Service.
          </p>
          <p className="text-richblack-700 leading-relaxed mt-4">
            You can choose to accept or decline cookies via your browser
            settings. Please note, declining cookies may affect the
            functionality of certain website features.
          </p>
        </section>

        {/* Service Providers */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-richblack-800 mb-4">
            Service Providers
          </h2>
          <p className="text-richblack-700 leading-relaxed">
            We may employ third-party companies and individuals to:
          </p>
          <ul className="list-disc pl-6 mt-2 text-richblack-700">
            <li>Facilitate our Service</li>
            <li>Provide the Service on our behalf</li>
            <li>Perform Service-related tasks</li>
            <li>Analyze how our Service is used</li>
          </ul>
          <p className="text-richblack-700 leading-relaxed mt-4">
            These third parties may have access to your Personal Information
            solely to perform tasks on our behalf and are obligated not to
            disclose or use the information for other purposes.
          </p>
        </section>

        {/* Security */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-richblack-800 mb-4">
            Security
          </h2>
          <p className="text-richblack-700 leading-relaxed">
            We value your trust in providing us with your Personal Information
            and use commercially acceptable measures to protect it. However, no
            method of transmission over the internet or electronic storage is
            100% secure, and we cannot guarantee absolute security.
          </p>
        </section>

        {/* Links to Other Sites */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-richblack-800 mb-4">
            Links to Other Sites
          </h2>
          <p className="text-richblack-700 leading-relaxed">
            Our Service may contain links to third-party websites. If you click
            on a link, you will be directed to that site. Note that we are not
            responsible for the privacy practices or content of these
            third-party sites. We encourage you to review their Privacy
            Policies.
          </p>
        </section>

        {/* Children’s Privacy */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-richblack-800 mb-4">
            Children’s Privacy
          </h2>
          <p className="text-richblack-700 leading-relaxed">
            Our Service is not intended for individuals under the age of 13. We
            do not knowingly collect Personal Information from children under
            13. If we discover that a child under 13 has provided us with
            personal information, we delete it immediately. If you believe your
            child has provided us with their information, please contact us.
          </p>
        </section>

        {/* Changes to This Privacy Policy */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-richblack-800 mb-4">
            Changes to This Privacy Policy
          </h2>
          <p className="text-richblack-700 leading-relaxed">
            We may update this Privacy Policy periodically. We recommend you
            review this page regularly for changes. Any updates to the Privacy
            Policy are effective immediately upon being posted on this page.
          </p>
        </section>

        {/* Contact Us */}
        <section>
          <h2 className="text-2xl font-semibold text-richblack-800 mb-4">
            Contact Us
          </h2>
          <p className="text-richblack-700 leading-relaxed">
            If you have any questions or suggestions about our Privacy Policy,
            please feel free to contact us at:
          </p>
          <ul className="list-disc pl-6 mt-2 text-richblack-700">
            <li>Email: support@demongears.com</li>
            <li>Phone: +91-8882531564</li>
            {/* <li>Address: DemonGears Private Limited, [Your Address Here]</li> */}
          </ul>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
