import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DefaultButton from "../common/DefaultButton";
import { buyProducts } from "../../services/operations/Payment";
import { useNavigate } from "react-router-dom";

const AmountBuyBox = ({ discountPrice, originalPrice }) => {
  // const { totalItems, cart } = useSelector((state) => state.cart);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="min-w-[280px]  rounded-md border-[1px] border-red-300 bg-red-50 p-6">
      <div className="mt-3 flex justify-between  space-x-3">
        <p className="text-richblack-500 text-lg">Amount:</p>
        <div className="flex gap-2">
          <span className=" font-semibold text-green-00 text-xl">
            ₹{discountPrice}
          </span>
          <span className="text-gray-400 line-through text-lg">
            ₹{originalPrice}
          </span>
        </div>
      </div>

      <div className="mt-2 flex items-end flex-col">
        <p className="text-xs text-richblack-300">Include 18% GST</p>
        <p className="text-xs text-richblack-300">
          {" "}
          Does not include delivery charges
        </p>
      </div>

      <div className="mt-5">
        <DefaultButton
          text={"Checkout"}
          onclick={() => navigate("/checkout")}
        />
      </div>
    </div>
  );
};

export default AmountBuyBox;
