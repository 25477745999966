import { toast } from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { feedbackEndpoints } from "../apis";

const { CREATE_FEEDBACK_API, GET_ALL_FEED_API } = feedbackEndpoints;

export const createFeedback = async (body) => {
  const toastId = toast.loading("Loading...");
  let result = null;
  try {
    const response = await apiConnector("POST", CREATE_FEEDBACK_API, {
      body,
    });
    toast.success("Feedback sent Successfully");
    result = response?.data;
  } catch (error) {
    console.log("error...................", error);
    toast.error(error.message);
  }

  toast.dismiss(toastId);
  return result;
};
