import { useState } from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  IconButton,
  Drawer,
} from "@material-tailwind/react";
import { FaArrowLeft } from "react-icons/fa";
import {
  PresentationChartBarIcon,
  ShoppingBagIcon,
  UserCircleIcon,
  Cog6ToothIcon,
  InboxIcon,
  Bars3Icon,
} from "@heroicons/react/24/solid";
import { Link, useNavigate } from "react-router-dom";
import { DialogDefault } from "./DialogDefault";

export function DefaultSidebar() {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false); // State to toggle drawer

  const handleNavigate = (path) => {
    navigate(path);
    setOpenDrawer(false); // Close drawer when navigating
  };

  return (
    <div className="relative">
      <div className="lg:hidden p-4 fixed  left-2 z-50">
        <IconButton
          variant="text"
          onClick={() => setOpenDrawer(true)}
          className="text-black"
        >
          <Bars3Icon className="h-8 w-8 text-black" />
        </IconButton>
      </div>

      {/* Sidebar for large screens */}
      <div className="hidden lg:block">
        <Card className="h-[100vh] w-full max-w-[20rem] p-4 shadow-xl shadow-richblack-500">
          {/* <div className="mb-2 p-4">
            <Link to="/">
              <FaArrowLeft className="h-6 w-6 text-blue-gray-500 hover:text-blue-700 cursor-pointer" />
            </Link>
          </div> */}
          <SidebarContent handleNavigate={handleNavigate} />
        </Card>
      </div>

      {/* Drawer for small screens */}
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        className="p-4"
        placement="left"
      >
        <SidebarContent handleNavigate={handleNavigate} />
      </Drawer>
    </div>
  );
}

function SidebarContent({ handleNavigate }) {
  return (
    <List>
      <div className="mb-2 p-4">
        <Link to="/">
          <FaArrowLeft className="h-6 w-6 text-blue-gray-500 hover:text-blue-700 cursor-pointer" />
        </Link>
      </div>
      {/* Dashboard */}
      <ListItem onClick={() => handleNavigate("/profile/dashboard")}>
        <ListItemPrefix>
          <PresentationChartBarIcon className="h-5 w-5" />
        </ListItemPrefix>
        Dashboard
      </ListItem>

      {/* Products */}
      <ListItem onClick={() => handleNavigate("/profile/allproducts")}>
        <ListItemPrefix>
          <ShoppingBagIcon className="h-5 w-5" />
        </ListItemPrefix>
        Products
      </ListItem>

      {/* Add Product */}
      <ListItem onClick={() => handleNavigate("/profile/add-products")}>
        <ListItemPrefix>
          <InboxIcon className="h-5 w-5" />
        </ListItemPrefix>
        Add Product
      </ListItem>

      {/* Recent Orders */}
      <ListItem onClick={() => handleNavigate("/profile/recent-orders")}>
        <ListItemPrefix>
          <UserCircleIcon className="h-5 w-5" />
        </ListItemPrefix>
        Recent Orders
      </ListItem>

      {/* Settings */}
      {/* <ListItem onClick={() => handleNavigate("/profile/settings")}>
        <ListItemPrefix>
          <Cog6ToothIcon className="h-5 w-5" />
        </ListItemPrefix>
        Settings
      </ListItem> */}

      {/* Log Out */}
      <ListItem>
        <DialogDefault />
      </ListItem>
    </List>
  );
}
