export const termsAndCondition = [
  {
    title: "Section 1 - Online Store Terms",
    content: `By agreeing to these Terms of Service, you represent that you are at least the
            age of majority in your state or province of residence, or that you are the age of
            majority in your state or province of residence and you have given us your consent to
            allow any of your minor dependents to use this site.
            
            You may not use our products for any illegal or unauthorized purpose nor may you, in
            the use of the Service, violate any laws in your jurisdiction (including but not
            limited to copyright laws).
            
            You must not transmit any worms, viruses, or any code of a destructive nature.
            A breach or violation of any of the Terms will result in an immediate termination of
            your Services.`,
  },
  {
    title: "Section 2 - General Conditions",
    content: `We reserve the right to refuse service to anyone for any reason at any time. You
            understand that your content (not including credit card information), may be transferred
            unencrypted and involve (a) transmissions over various networks; and (b) changes to
            conform and adapt to technical requirements of connecting networks or devices. Credit
            card information is always encrypted during transfer over networks.
            
            You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the
            Service, use of the Service, or access to the Service or any contact on the website
            through which the service is provided, without express written permission by us.`,
  },
  {
    title: "Section 3 - Products or Services",
    content: `All products offered through DemonGears are subject to availability. We reserve the
            right to discontinue any product at any time. Prices for our products are subject to
            change without notice.
            
            We have made every effort to display as accurately as possible the colors and images of
            our products. However, we cannot guarantee that your device's display will accurately
            reflect the actual color of the product.`,
  },
  {
    title: "Section 4 - Returns and Refunds",
    content: `Our Returns and Refunds Policy forms part of these Terms of Service. For more
            details on returns, refunds, and exchanges, please visit our Returns & Refunds Policy
            page [link].`,
  },
  {
    title: "Section 5 - Modifications to the Service and Prices",
    content: `Prices for our products are subject to change without notice. We reserve the right
            at any time to modify or discontinue the Service (or any part or content thereof)
            without notice.`,
  },
  {
    title: "Section 6 - Accuracy of Information",
    content: `We are not responsible if information made available on this site is not accurate,
            complete, or current. Any reliance on the material on this site is at your own risk.`,
  },
  {
    title: "Section 7 - Governing Law",
    content: `These Terms of Service and any separate agreements shall be governed by and
            construed in accordance with the laws of India.`,
  },
];
