// GET_ALL_ORDERS_OF_ADMIN_API;
import { toast } from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { orderEndpoints } from "../apis";

const {
  GET_ALL_ORDERS_OF_ADMIN_API,
  GET_ALL_ORDERS_OF_USER_API,
  GET_ORDER_BY_ID,
  UPDATE_ORDER_API,
} = orderEndpoints;

export async function getAllOrdersForAdmin() {
  try {
    const response = await apiConnector("GET", GET_ALL_ORDERS_OF_ADMIN_API);

    if (!response.data.success) {
      throw new Error(response.data.message);
    }

    return response.data;
  } catch (error) {
    toast.error("Something went wrong");
  }
}

export async function getAllOrdersForUser(userId, token) {
  try {
    const response = await apiConnector(
      "GET",
      GET_ALL_ORDERS_OF_USER_API + `/${userId}`,
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    // if (!response.data.success) {
    //   throw new Error(response.data.message);
    // }

    return response.data;
  } catch (error) {
    toast.error("Something went wrong");
  }
}

export async function updateOrderStatusApi(token, orderId, orderStatus) {
  try {
    const response = await apiConnector(
      "put",
      UPDATE_ORDER_API + `/${orderId}`,
      { orderStatus },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    return response.data;
  } catch (error) {
    toast.error("Something went wrong");
  }
}

export async function fetchOrderDetailsById(token, orderId) {
  try {
    const response = await apiConnector(
      "GET",
      GET_ORDER_BY_ID + `/${orderId}`,
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );

    return response.data;
  } catch (error) {
    toast.error("Something went wrong");
  }
}
