import { toast } from "react-hot-toast";
import { setLoading, setToken } from "../../slices/authSlice";
import { apiConnector } from "../apiConnector";
import { setUser } from "../../slices/profileSlice";

import { authEndpoints } from "../apis";
import { resetCart } from "../../slices/cartSlice";
const { SEND_OTP_API, SIGNIN_API, SIGNUP_API, CHANGE_PASSWORD_API } =
  authEndpoints;

export function signUp(
  firstName,
  lastName,
  email,
  password,
  confirmPassword,
  otp,
  navigate
) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", SIGNUP_API, {
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        otp,
      });

      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      toast.success("Signup Successful");
      navigate("/login");
    } catch (error) {
      toast.error("Signup Failed");
      navigate("/signup");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export function login(email, password, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", SIGNIN_API, {
        email,
        password,
      });

      if (!response?.data?.success) {
        throw new Error(response.data.message);
      }
      console.log("response ", response);
      toast.success("Login successful");
      dispatch(setToken(response.data.token));
      localStorage.setItem("token", JSON.stringify(response.data.token));
      localStorage.setItem("user", JSON.stringify(response.data.user));
      dispatch(setUser({ ...response.data.user }));

      if (response.data.user.role === "user") {
        navigate("/profile/settings");
      } else if (response.data.user.role === "admin") {
        navigate("/profile/dashboard");
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Login Failed, Please try again");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export function logout(navigate) {
  return (dispatch) => {
    dispatch(setToken(null));
    dispatch(setUser(null));
    dispatch(resetCart());
    localStorage.clear();
    toast.success("Logged Out");
    navigate("/profile");
  };
}

export function sendOtp(email, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", SEND_OTP_API, {
        email,
      });
      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      toast.success("Otp sent successfully");
      navigate("/verify-email");
    } catch (error) {
      toast.error("Could not send otp");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export async function changePassword(data, token) {
  const toasId = toast.loading("Loading...");

  try {
    const response = await apiConnector(
      "POST",
      CHANGE_PASSWORD_API,
      { data },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (!response.data.success) {
      throw new Error(response.data.message);
    }
    toast.success("Password changed successfully");
  } catch (error) {
    toast.error("Something went wrong");
  }

  toast.dismiss(toasId);
}
