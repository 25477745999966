// data.js

export const faqData = [
  {
    id: 1,
    question: "What is your return policy?",
    answer:
      "If you're not satisfied with your purchase, we accept returns within 7 days of delivery. To initiate a return, please email us at support@myecommercestore.com with your order number and a brief explanation of why you're returning the item.",
  },
  {
    id: 2,
    question: "How do I track my order?",
    answer:
      "You can track your order by clicking the tracking link in your shipping confirmation email, or by logging into your account on our website and viewing the order details.",
  },
  {
    id: 3,
    question: "How do I contact customer support?",
    answer:
      "You can contact our customer support team by emailing us at support@myecommercestore.com, or by calling us at (555) 123-4567 between the hours of 9am and 5pm EST, Monday through Friday.",
  },
  {
    id: 4,
    question: "Can I change or cancel my order?",
    answer:
      "Unfortunately, once an order has been placed, we are not able to make changes or cancellations. If you no longer want the items you've ordered, you can return them for a refund within 7 days of delivery.",
  },
  {
    id: 5,
    question: "Do you offer international shipping?",
    answer: "Currently, we only offer shipping within the United States.",
  },
  {
    id: 6,
    question: "What payment methods do you accept?",
    answer:
      "We accept Visa, MasterCard, American Express, Discover, and PayPal.",
  },
];
